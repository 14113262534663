import React, { useState, useEffect, useRef, useCallback } from 'react';
import
    {
        Image,
        Divider,
        HStack,
        Pressable,
        Hidden,
        Box,
        Badge,
        VStack,
        Text,
        Button,
        Input,
        CloseIcon,
        ScrollView,
        FlatList,
        Tooltip,
        HamburgerIcon,
        Center,
        ChevronDownIcon,
        ChevronUpIcon,
    } from 'native-base';
import LogoSvg from '../../assets/images/Actavivo-Logo.svg';
import Dropdown from '../../assets/images/dropdown.svg';
import { AVAuth } from '../../Platform/AVAuth';
import AVAdd from '../../components/common/AVAdd';
import { useLocation } from 'react-router-dom';
import { Alert } from '../../AVCore/Alert';
import Moment from 'moment';
import
    {
        NotificationIcon,
        MessageIcon,
        SearchIcon,
        TickIcon,
        FollowingIcon,
        CrossIcon,
        EventsIcon,
        GroupIcon,
        CreateClass, CreateCommunity, CreateElite, CreateGraduation, CreateGroup, CreateNeighborhood, CreateOrganization, CreateSchool, CreateTeam, ChatIcon, CreateClub
    } from '../../assets/icons/icon-assets';
import QuickSearch from './QuickSearch';
import Menu from './Menu';
import ProfilePic from '../common/ProfilePicture';
import { Person } from '../../AVCore/Person';
import AVGradientBox from '../../Platform/AVGradientBox';
import useConvoChat from '../../Hooks/useConvoChat';
import useFollowRequest from '../../Hooks/useFollowRequest';
import useAlerts from '../../Hooks/useAlerts';
import useConvoList from '../../Hooks/useConvoList';
import { Link } from '../../AVCore/Link';
import * as AV from '../../AVCore/AVCore';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AHStack, AVStack } from '../../components/ui';
import AVCancel from '../../components/common/AVCancel';
import { TouchableOpacity } from 'react-native';
import { setUser, setFeedsLoad, setToastShow, setLastFeedUpdate, setEntitydata } from '../../Redux/Actions/userActions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setFeeds, setFeedsScrollUpdate, setFeedsUpdate } from '../../Redux/Actions/feedsAction';
import { setAlerts, setNewAlerts } from '../../Redux/Actions/alertActions';
import { setConvo } from '../../Redux/Actions/convoActions';
import { Loc } from '../../App/Loc';
import { useWindowDimension } from '../../Utility/WindowDimensions';
import { Server } from '../../Platform/Server';
import { setLoginLink, setuserisguardian, setFollowRequestData } from '../../Redux/Actions/userActions';
import seting from '../../assets/images/setings.png';
import about from '../../assets/images/About.png';
import adduser from '../../assets/images/AddUser.png';
import help from '../../assets/images/help.png';
import _ from 'lodash';
import { isMobile as isMobileDevice } from 'react-device-detect';
import InviteFriends from './InviteFriends';
import AVImageSlider from 'components/common/AVImageSlider';
import { Media } from '../../AVCore/Media';
import CreateNewMessage from './CreateNewMessage';
import Messages from './Messages';
import useClearCache from '../../Hooks/useClearCache';

const Logo = React.memo( () =>
{
    let person = useSelector( ( state : RootStateOrAny ) => state?.user?.user[ 0 ] );
    const dispatch = useDispatch();
    const { handlerefetch } = useAlerts();

    return (
        <React.Fragment>
            <Pressable isDisabled={ person?.status === 'Deleted' }
                onPress={ () =>
                {
                    if ( person?.status !== 'Deleted' )
                    {
                        dispatch( setFeedsScrollUpdate( true ) );
                        handlerefetch();
                    }
                } }>
                { ( () =>
                {
                    if ( person?.status !== 'Deleted' )
                    {
                        return (
                            <RouterLink to='/'>
                                <Image
                                    source={ { uri: LogoSvg } }
                                    alt='Actavivo'
                                    size={ [ '40px', '56px' ] }
                                    color='red.200'
                                />
                            </RouterLink>
                        );
                    } else
                    {
                        return (
                            <Image
                                source={ { uri: LogoSvg } }
                                alt='Actavivo'
                                size={ [ '40px', '56px' ] }
                                color='red.200'
                            />
                        );
                    }
                } )() }
            </Pressable>
        </React.Fragment>
    );
} );
function Header ( props : any )
{
    const location : any = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dimensions = useWindowDimension();
    const stateUser = useSelector( ( state : RootStateOrAny ) => state );
    let userFeeds : any = useSelector( ( state : RootStateOrAny ) => state?.feeds.feeds[ 0 ] );
    const followRequestData : any = useSelector( ( state : RootStateOrAny ) => state?.user?.followRequestData );
    const lastUpdateFeed = useSelector( ( state : RootStateOrAny ) => state.user.lastUpdateFeed );
    const [ commentValue, setCommentValue ] = React.useState<any>( '' );
    const [ lastfeed, setlastfeed ] = useState<any>( [] );

    React.useEffect( () =>
    {
        if ( location?.state?.convovalue )
            handleGetConvo( location?.state?.convovalue );
    }, [] );
    const currentUser = stateUser?.user?.user[ 0 ];
    const useralertList = useSelector( ( state : RootStateOrAny ) => state?.alerts?.alert[ 0 ]?.length > 0 ? state?.alerts?.alert[ 0 ] : [] );
    const followreqlist = useralertList?.filter(
        ( x ) =>
            x?.actionTaken === undefined &&
            x?.alertType !== 'NewRequest' &&
            ( x?.data?.type === 'Follow' || x?.type === 'Follow' || x?.data?.type === "StaffRequest" || x?.type === "StaffRequest" || x?.data?.type === "OrgParent" || x?.type == "OrgParent" || x?.alertType == 'NewRequestToStaff' )
    );
    const customUniq = ( array ) =>
        _.uniqWith(
            array,
            ( a : any, b : any ) =>
                a?.id === b?.id &&
                a?.source?.id === b?.source?.id &&
                a?.target?.id === b?.target?.id &&
                a?.type === b?.type
        );
    const [ requestdata, setrequestdata ] = React.useState(
        _.orderBy(
            customUniq( [
                ...( followRequestData || [] ),
                ...( currentUser?.requests?.filter( ( x ) => x?.actionTaken === undefined ) || [] ),
                ...( followreqlist || [] ),
            ] ),
            [ 'date' ],
            [ 'desc' ]
        )
    );
    const [ requestdata1, setrequestdata1 ] = React.useState(
        _.orderBy(
            customUniq( [
                ...( followRequestData || [] ),
                ...( currentUser?.requests?.filter( ( x ) => x?.actionTaken === undefined ) || [] ),
                ...( followreqlist || [] ),
            ] ),
            [ 'date' ],
            [ 'desc' ]
        )
    );
    const [ managerequestdata, setManageRequestdata ] = React.useState( [] );
    const [ singleImage, setSingleImage ] = useState();
    const [ allImages, setShowAllImage ] = useState( [] );

    //Bind follow request modal
    const {
        handleUnFollow,
        unfollowLoader,
    } = useFollowRequest();
    //Bind Convo list modal
    const { showConvoListModal, setShowConvoListModal } = useConvoList();
    const convoval = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const [ convoListData, setconvoListData ] = React.useState( convoval );
    const [ convosearch, setconvosearch ] = React.useState( false );
    let userconvolistdata = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] )?.sort( function ( a, b )
    {
        let c : any = new Date( b?.lastUpdated );
        let d : any = new Date( a?.lastUpdated );
        return c - d;
    } );

    const userconvo = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const usernewalert = useSelector( ( state : RootStateOrAny ) => state?.alerts?.newalert[ 0 ] );
    const entitydatalist : any = useSelector( ( state : RootStateOrAny ) => state.user.entitydatalist );
    useralertList?.sort( function ( a, b )
    {
        let c : any = new Date( b.date );
        let d : any = new Date( a.date );
        return c - d;
    } );
    const [ allAlertData, setAllAlertData ] = React.useState( [] );
    const [ unreadAlertData, setUnreadAlertData ] = React.useState( [] );
    useEffect( () =>
    {
        setManageRequestdata( currentUser?.follows );
        setrequestdata(
            _.orderBy(
                customUniq( [
                    ...( followRequestData || [] ),
                    ...( currentUser?.requests?.filter( ( x ) => x?.actionTaken === undefined ) || [] ),
                    ...( followreqlist || [] ),
                ] ),
                [ 'date' ],
                [ 'desc' ]
            )
        );
        setrequestdata1(
            _.orderBy(
                customUniq( [
                    ...( followRequestData || [] ),
                    ...( currentUser?.requests?.filter( ( x ) => x?.actionTaken === undefined ) || [] ),
                    ...( followreqlist || [] ),
                ] ),
                [ 'date' ],
                [ 'desc' ]
            )
        );

    }, [ currentUser, followRequestData ] );
    const isConvoScreenOpen = useSelector( ( state : RootStateOrAny ) => state.user.isConvoScreenOpen );

    useEffect( () =>
    {

        if ( useralertList?.length > 0 )
        {
            const filterdatalist = ( x ) =>
                x?.alertType !== "Blocked" &&
                x?.alertType !== "UpdateDM" &&
                x?.alertType !== "DeleteDM" &&
                x?.alertType !== "UnBlocked" &&
                x?.alertType !== "Block" &&
                x?.alertType !== "UnBlock" &&
                x.alertType !== 'EntityUpdate' &&
                x?.alertType !== "NewRequest" &&
                x.alertType !== 'DeletePost' &&
                x?.alertType !== "Rejected" &&
                x?.wardName === undefined &&
                x?.alertType !== "NewRequestToStaff" &&
                x?.alertType !== "Accepted" &&
                x?.data?.type !== "Follow" &&
                x?.alertType !== "Dependant" &&
                x?.alertType !== "VisChange" &&
                x?.alertType !== "ConvoUpdate"

            let filteredAlerts = useralertList?.filter( ( x ) =>
                loginLink
                    ? filterdatalist( x )
                    : filterdatalist( x ) &&
                    x?.alertType !== "NewDM-Ward" &&
                    x?.alertType !== "ReplyDM-Ward" &&
                    x?.alertType !== "NewRequest-Ward"
            );

            const selfUpdatePost = filteredAlerts?.filter(
                ( x ) =>
                    ( ( x?.alertType === "UpdatePost" ||
                        x?.alertType === "PostReply" ||
                        x?.alertType === "NewPost" ||
                        x?.alertType === "Impression" ) &&
                        x?.data?.person?.id === currentUser?.id ) ||
                    ( x?.alertType == "Impression" && x?.data?.source?.id == currentUser?.id )
            );
            filteredAlerts = filteredAlerts?.filter(
                ( item1 ) => !selfUpdatePost?.some( ( item2 ) => item2?.id === item1?.id )
            );

            const unreadAlertData = filteredAlerts?.filter( ( x ) => x?.actionTaken === undefined );
            const selfConvo = filteredAlerts?.filter(
                ( x ) => x?.alertType === "NewDM" && x?.data?.person?.id === currentUser?.id
            );
            const selfConvoReply = filteredAlerts?.filter(
                ( x ) => x?.alertType === "ReplyDM" && x?.data?.person?.id === currentUser?.id
            );

            const updatedAllAlertData = filteredAlerts?.filter(
                ( item1 ) => !selfConvo.some( ( item2 ) => item2?.id === item1?.id ) &&
                    !selfConvoReply.some( ( item2 ) => item2?.id === item1?.id )
            );
            setAllAlertData( updatedAllAlertData );

            const updatedUnreadAlertData = unreadAlertData?.filter(
                ( item1 ) => !selfConvo.some( ( item2 ) => item2?.id === item1?.id )
            );
            setUnreadAlertData( updatedUnreadAlertData );
        }

    }, [ useralertList, currentUser, isConvoScreenOpen ] );

    const list_to_tree = ( list ) =>
    {
        var map = {},
            node,
            roots : any = [],
            i;

        for ( i = 0; i < list?.length; i += 1 )
        {
            map[ list[ i ]?.id ] = i; // initialize the map
            if ( list[ i ]?.children?.length === 0 || !list[ i ]?.children ) list[ i ][ 'children' ] = []; // initialize the children
            if ( list[ i ]?.galleryPost?.length === 0 || !list[ i ]?.galleryPost ) list[ i ][ 'galleryPost' ] = [];
        }

        for ( i = 0; i < list?.length; i += 1 )
        {
            node = list[ i ];
            if ( node.parentPost !== undefined )
            {
                if (
                    list[ map[ node.parentPost ] ]?.gallery?.items?.filter( ( x ) => x === node.id )?.length > 0 &&
                    node.gallery == undefined
                )
                {
                    let excitingpost = list[ map[ node.parentPost ] ]?.galleryPost?.filter(
                        ( x ) => x?.id === node?.id
                    );
                    if ( excitingpost?.length === 0 || excitingpost === undefined )
                        list[ map[ node.parentPost ] ]?.galleryPost.push( node );
                } else
                {
                    let excitingcomment = list[ map[ node.parentPost ] ]?.children?.filter(
                        ( x ) => x?.id === node?.id
                    );
                    if ( excitingcomment?.length === 0 || excitingcomment === undefined )
                        list[ map[ node.parentPost ] ]?.children.push( node );
                }
            } else
            {
                roots.push( node );
            }
        }
        return roots;
    };

    const getnewOrUpdatedPost = async () =>
    {
        let excitingpost = userFeeds?.filter( ( x ) => x?.id == usernewalert?.target?.id );
        await AV.Post.get( usernewalert?.target ).then( async ( post : any ) =>
        {
            if ( post?.status !== 'Deleted' )
            {
                if ( post?.gallery?.items?.length > 0 )
                {
                    for ( var i = 0; i < post?.gallery?.items?.length; i++ )
                    {
                        await AV.Post.get( {
                            id: post?.gallery?.items[ i ],
                            pk: post?.pk,
                            region: post?.region,
                            type: post?.type,
                        } ).then( ( parentPost ) =>
                        {
                            if ( !post?.galleryPost || post?.galleryPost?.length === 0 )
                                post.galleryPost = [];
                            post.galleryPost.push( parentPost );
                        } );
                    }
                }
                let excitingpost1 = userFeeds?.filter( ( x ) => x?.id == usernewalert?.target?.id );
                if ( post && excitingpost1?.length === 0 )
                {
                    userFeeds.unshift( post );
                    let updatedFeeds = list_to_tree( userFeeds );
                    dispatch( setFeeds( updatedFeeds ) );
                }
            }
        } );
    }

    React.useEffect( () =>
    {
        async function fetchData ()
        {
            switch ( usernewalert?.alertType )
            {
                case 'DeletePost':
                    if ( currentUser?.id !== usernewalert?.target?.id )
                    {
                        let userfeedsValue = userFeeds?.filter( ( x ) => x?.id === usernewalert?.target?.id );
                        if ( userfeedsValue?.length > 0 )
                        {
                            let finalValue = userFeeds?.filter( ( x ) => x?.id !== usernewalert?.target?.id );
                            dispatch( setFeeds( finalValue ) );
                        }
                        else
                        {
                            //let post = await AV.Post.get(usernewalert?.target);
                            //let ppost = userFeeds?.filter((x) => x.id === post?.parentPost);
                            //if (ppost?.length > 0) {
                            //    let finalValue = ppost[ 0 ]?.children?.filter((x) => x?.id !== usernewalert?.target?.id);
                            //    if (finalValue?.length > 0) {
                            //        ppost[ 0 ].children = []
                            //        ppost[ 0 ].children.unshift(finalValue);
                            //    }
                            //    else
                            //        ppost[ 0 ].children = []
                            //    if (finalValue?.length > 0)
                            //        ppost[ 0 ].posts = ppost[ 0 ].posts?.filter((x) => x != usernewalert?.target?.id)
                            //    else
                            //        ppost[ 0 ].posts = []
                            //    userFeeds[ userFeeds.indexOf(ppost[ 0 ]) ] = ppost[ 0 ];
                            //    dispatch(setFeeds(userFeeds));
                            //}
                        }
                        let alertsval = useralertList?.filter(
                            ( x ) => x?.target?.id !== usernewalert?.target?.id
                        );
                        alertsval = _.uniqBy( alertsval, 'id' );
                        dispatch( setAlerts( alertsval ) );
                        handleSaveAlert( usernewalert, "deleted" )
                    }
                    break;
                case "ConvoUpdate":
                    const feedParam : AV.IFeedParam = {
                        feed: { id: usernewalert?.target?.id, pk: usernewalert?.target?.id, region: currentUser?.region, type: "Convo" },
                        startDate: new Date( 0 ),
                        forward: true,
                    };

                    const response : any = await AV.Feed.getFeed( feedParam );
                    if ( response?.id )
                    {
                        /* var userPeople = response?.people?.filter( ( x ) => response?.people?.length === 2 && x.id !== currentUser?.id );*/
                        response.people = _.uniqBy( response.people, 'id' );
                        let Index = userconvo?.findIndex( ( x ) => x?.id == response?.id );
                        if ( userconvo !== -1 )
                        {
                            userconvo[ Index ].people = response?.people
                        }
                        dispatch( setConvo( userconvo ) );
                    }
                    break;
                case 'NewEvent':
                    let excitingfeeds = userFeeds?.filter( ( x ) => x?.owner.id === usernewalert?.target?.id );
                    let param : AV.IFeedParam = {
                        feed: AV.AVEntity.getFk( usernewalert?.target ),
                        startDate: new Date( excitingfeeds?.length > 0 ? excitingfeeds[ 0 ]?.lastUpdated : 0 ),
                        forward: false
                    };
                    if ( param?.feed?.id )
                    {
                        AV.Feed.getFeed( param ).then( ( responsepost : any ) =>
                        {
                            if ( responsepost )
                            {
                                let feeds = userFeeds.concat(
                                    responsepost.posts.filter( ( x ) => x.status !== 'Deleted' )
                                );
                                feeds = _.uniqBy( feeds, 'id' );
                                dispatch( setFeeds( feeds ) );
                            }
                        } );
                    }
                    break;
                case 'CancelEvent':
                    let userFeedList = userFeeds?.filter( ( x ) => x?.owner?.id !== usernewalert?.target?.id );
                    dispatch( setFeeds( userFeedList ) );
                    break;
                case 'NewPost':
                    await getnewOrUpdatedPost();
                    break;
                case 'UpdatePost':
                    const UpdatePost = userFeeds?.find( ( x : any ) => x?.id === usernewalert?.target?.id );
                    if ( UpdatePost )
                    {
                        await AV.Post.get( usernewalert?.target ).then( async ( post : any ) =>
                        {
                            if ( post?.status !== 'Deleted' )
                            {
                                if ( post?.gallery?.items?.length > 0 )
                                {
                                    for ( var i = 0; i < post?.gallery?.items?.length; i++ )
                                    {
                                        await AV.Post.get( {
                                            id: post?.gallery?.items[ i ],
                                            pk: post?.pk,
                                            region: post?.region,
                                            type: post?.type,
                                        } ).then( ( parentPost ) =>
                                        {
                                            if ( !post?.galleryPost || post?.galleryPost?.length === 0 )
                                                post.galleryPost = [];
                                            post.galleryPost.push( parentPost );
                                        } );
                                    }
                                }
                                let excitingpost1 = userFeeds?.findIndex( ( x ) => x?.id == usernewalert?.target?.id );
                                if ( excitingpost1 != -1 )
                                {
                                    userFeeds[ excitingpost1 ] = post
                                    let updatedFeeds = list_to_tree( userFeeds );
                                    dispatch( setFeeds( updatedFeeds ) );
                                }
                            }
                        } );
                    }
                    else
                    {
                        await getnewOrUpdatedPost();
                    }
                    break;
                case 'VisChange':
                    if ( currentUser?.id !== usernewalert?.target?.id )
                    {
                        const userfeedsValue = userFeeds?.filter( ( x ) => x?.id === usernewalert?.target?.id );
                        if ( userfeedsValue?.length > 0 )
                        {
                            userFeeds = userFeeds?.filter( ( x ) => x?.id !== usernewalert?.target?.id );
                            dispatch( setFeeds( userFeeds ) );
                        }
                    }
                    await getnewOrUpdatedPost();
                    break;
                case 'NewRequest':
                case 'Follow':
                    AV.Person.getSelf( true ).then( ( response ) => { dispatch( setUser( response ) ) } );
                    break;
                case 'EntityUpdate':
                    if ( usernewalert?.data !== 'AddList' && usernewalert?.data !== 'SelfConvoPost' && usernewalert?.data )
                    {
                        AV.Person.getSelf( true ).then( ( response : any ) =>
                        {
                            dispatch( setUser( response ) );
                            if ( usernewalert?.data === 'AddFollow' )
                            {
                                dispatch( setUser( response ) );
                                setrequestdata(
                                    _.orderBy(
                                        customUniq( [
                                            ...( followRequestData || [] ),
                                            ...( currentUser?.requests?.filter( ( x ) => x?.actionTaken === undefined ) || [] ),
                                            ...( followreqlist || [] ),
                                        ] ),
                                        [ 'date' ],
                                        [ 'desc' ]
                                    )
                                );
                                setrequestdata1(
                                    _.orderBy(
                                        customUniq( [
                                            ...( followRequestData || [] ),
                                            ...( currentUser?.requests?.filter( ( x ) => x?.actionTaken === undefined ) || [] ),
                                            ...( followreqlist || [] ),
                                        ] ),
                                        [ 'date' ],
                                        [ 'desc' ]
                                    )
                                );
                                setManageRequestdata( response?.follows );
                                if ( response != null )
                                {
                                    let i = response?.follows?.length - 1;
                                    let item = response?.follows[ i ];
                                    if ( window.location.pathname === '/profile/' + item?.id )
                                    {
                                        navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                            state: {
                                                user: {
                                                    id: item?.id,
                                                    pk: item?.pk,
                                                    region: item?.region,
                                                    type: item?.type,
                                                    name: item?.title,
                                                },
                                            },
                                        } );
                                    }
                                    let excitingfeeds = userFeeds?.filter( ( x ) => x?.owner.id === item?.id );
                                    if ( excitingfeeds?.length === 0 )
                                    {
                                        let param : AV.IFeedParam = {
                                            feed: AV.AVEntity.getFk( item ),
                                            startDate: new Date( 0 ),
                                            forward: false
                                        };
                                        if ( param?.feed?.id )
                                        {
                                            AV.Feed.getFeed( param ).then( ( responsepost : any ) =>
                                            {
                                                if ( responsepost )
                                                {
                                                    let feeds = userFeeds.concat(
                                                        responsepost.posts.filter( ( x ) => x.status !== 'Deleted' )
                                                    );
                                                    dispatch( setFeeds( feeds ) );
                                                }
                                            } );
                                        }
                                    }
                                }
                            }
                        } );
                    }
                    else
                    {
                        try
                        {
                            const timer = setTimeout( async () =>
                            {
                                try
                                {
                                    // Fetching the user data
                                    const response : any = await AV.Person.getSelf( true );

                                    // Updating the user conversation
                                    const updatedUserConvo = userconvo.map( ( convo ) =>
                                    {
                                        const matchingResponseConvo = response.convos.find(
                                            ( resConvo ) => resConvo.id === convo.id
                                        );
                                        if ( matchingResponseConvo )
                                        {
                                            return {
                                                ...convo,
                                                subject:
                                                    matchingResponseConvo.title || convo.subject, // Update subject if a title exists in response.convos
                                            };
                                        }
                                        return convo;
                                    } );

                                    // Log the updated conversations
                                    console.log( "updatedUserConvo::::::::::::::::::::::", updatedUserConvo );

                                    // Dispatching updated user conversations
                                    dispatch( setConvo( updatedUserConvo ) );

                                    // Updating the entity data list
                                    let index = entitydatalist?.findIndex( ( x ) => x?.id === response?.id );
                                    if ( index !== -1 )
                                    {
                                        entitydatalist[ index ] = response;
                                        dispatch( setEntitydata( entitydatalist ) );
                                    }

                                    // Dispatching updated user data
                                    dispatch( setUser( response ) );
                                } catch ( error )
                                {
                                    console.error( 'Error fetching user:', error );
                                }
                            }, 5000 );

                            // Cleanup function to clear the timeout if the component unmounts
                            return () => clearTimeout( timer );
                        } catch ( error )
                        {
                            console.error( 'Error in setTimeout:', error );
                        }
                    }
                    break;
                case 'DeleteDM':
                    handleConvoDelete( userconvo, usernewalert );
                    break;
                case 'PostReview':
                    let PostReview = userFeeds?.filter( ( x ) => x?.id == usernewalert?.target?.id );
                    if ( PostReview?.length > 0 && PostReview[ 0 ]?.modStatus !== 'NR' )
                    {
                        let post = PostReview[ 0 ];
                        post.modStatus = 'NR';
                        userFeeds[ userFeeds.indexOf( PostReview[ 0 ] ) ] = post;
                        dispatch( setFeeds( userFeeds ) );
                    }
                    else
                    {
                        let postreply = await AV.Post.get( usernewalert?.target );
                        let parentPost = await AV.Post.get( {
                            id: postreply?.parentPost,
                            pk: postreply?.pk,
                            region: postreply?.region,
                            type: postreply?.type,
                        } );
                        let ppost1 : any = userFeeds?.filter( ( x ) => x.id === parentPost?.id );
                        if ( ppost1?.length > 0 )
                        {
                            let finalPost = ppost1[ 0 ]?.children?.filter( ( x ) => x?.id === usernewalert?.target?.id );
                            finalPost[ 0 ].modStatus = 'NR';
                            let index = ppost1[ 0 ]?.children?.findIndex( ( x ) => x?.id === finalPost[ 0 ]?.id );
                            if ( index !== -1 )
                                ppost1[ 0 ].children[ index ] = finalPost[ 0 ]
                            userFeeds[ userFeeds.indexOf( ppost1[ 0 ] ) ] = ppost1[ 0 ];
                            dispatch( setFeeds( userFeeds ) );
                        }
                    }
                    break;
                case 'PostReply':
                    let postreply = await AV.Post.get( usernewalert?.target );
                    let ppost = userFeeds?.filter( ( x ) => x.id === postreply?.parentPost );
                    if ( ppost?.length > 0 )
                    {
                        if ( !ppost[ 0 ]?.children ) ppost[ 0 ].children = [];
                        if ( !ppost[ 0 ]?.posts ) ppost[ 0 ].posts = [];
                        let existingVal = ppost[ 0 ]?.children?.filter( ( x ) => x?.id === postreply?.id );
                        if ( existingVal?.length === 0 )
                        {
                            ppost[ 0 ].children.unshift( postreply ); ppost[ 0 ].posts.unshift( postreply?.id );
                            userFeeds[ userFeeds.indexOf( ppost[ 0 ] ) ] = ppost[ 0 ];
                            dispatch( setFeeds( userFeeds ) );
                            dispatch( setFeedsUpdate( true ) );
                        }
                        else
                        {
                            let index = ppost[ 0 ]?.children?.findIndex( ( x ) => x?.id === postreply?.id );
                            if ( index != -1 )
                            {
                                ppost[ 0 ].children[ index ] = postreply;
                                ppost[ 0 ].children[ index ].galleryPost = existingVal[ 0 ]?.galleryPost
                                userFeeds[ userFeeds.indexOf( ppost[ 0 ] ) ] = ppost[ 0 ];
                                dispatch( setFeeds( userFeeds ) );
                                dispatch( setFeedsUpdate( true ) );
                            }
                        }
                    }
                    else
                    {
                        let parentPost = await AV.Post.get( {
                            id: postreply?.parentPost,
                            pk: postreply?.pk,
                            region: postreply?.region,
                            type: postreply?.type,
                        } );
                        let ppost1 = userFeeds?.filter( ( x ) => x.id === parentPost?.parentPost );
                        if ( ppost1?.length > 0 )
                        {
                            let replyval = ppost1[ 0 ]?.children?.filter( ( x ) => x?.id === postreply?.parentPost );
                            if ( !replyval[ 0 ]?.children ) replyval[ 0 ].children = [];
                            let excitingreply = replyval[ 0 ]?.children?.filter( ( x ) => x?.id === postreply?.id );
                            if ( excitingreply?.length === 0 )
                            {
                                replyval[ 0 ].children.unshift( postreply );
                                replyval[ 0 ].posts.unshift( postreply?.id );
                                ppost1[ 0 ].children[ ppost1[ 0 ]?.children.indexOf( replyval[ 0 ] ) ] = replyval[ 0 ];
                                ppost1[ 0 ].posts[ ppost1[ 0 ]?.posts.indexOf( replyval[ 0 ] ) ] = replyval[ 0 ];
                                userFeeds[ userFeeds.indexOf( ppost1[ 0 ] ) ] = ppost1[ 0 ];
                                dispatch( setFeeds( userFeeds ) );
                                dispatch( setFeedsUpdate( true ) );
                            }
                        }
                    }
                    break;
                case 'Impression':
                    let userFeedsImpression = userFeeds?.filter( ( x ) => x?.id === usernewalert?.target?.id );
                    if ( userFeedsImpression?.length > 0 )
                    {
                        let Impression = usernewalert?.data?.source;
                        Impression.impression = usernewalert?.data?.type
                        userFeedsImpression[ 0 ].impressions = userFeedsImpression[ 0 ]?.impressions?.filter(
                            ( x ) => x.id !== usernewalert?.data?.source?.id
                        );
                        userFeedsImpression[ 0 ].impressions.unshift( Impression );
                        userFeeds[ userFeeds.indexOf( userFeedsImpression[ 0 ] ) ] = userFeedsImpression[ 0 ];
                        dispatch( setFeeds( userFeeds ) );
                        dispatch( setFeedsUpdate( true ) );
                    }

                    break;
            }
        }
        if ( usernewalert )
        {
            let alert : any = [];
            if ( useralertList?.length > 0 ) alert = useralertList;
            let existingVal = alert?.filter( ( x : any ) => x?.id === usernewalert?.id );
            if (
                existingVal?.length == 0 &&
                usernewalert?.alertType !== 'DeletePost' &&
                usernewalert?.alertType !== undefined
            )
            {
                alert.unshift( usernewalert );
                let alertlist = Array.from( new Set( alert ) );
                alertlist = _.uniqBy( alertlist, 'id' );
                dispatch( setAlerts( alertlist ) );
                dispatch( setNewAlerts( [] ) );
            }
        }

        fetchData();
        setrequestdata(
            _.orderBy(
                customUniq( [
                    ...( followRequestData || [] ),
                    ...( currentUser?.requests?.filter( ( x ) => x?.actionTaken === undefined ) || [] ),
                    ...( followreqlist || [] ),
                ] ),
                [ 'date' ],
                [ 'desc' ]
            )
        );
        setrequestdata1(
            _.orderBy(
                customUniq( [
                    ...( followRequestData || [] ),
                    ...( currentUser?.requests?.filter( ( x ) => x?.actionTaken === undefined ) || [] ),
                    ...( followreqlist || [] ),
                ] ),
                [ 'date' ],
                [ 'desc' ]
            )
        );
        const filterdatalist = ( x : any ) =>
            x?.alertType !== "Blocked" && x?.alertType !== "UpdateDM" && x?.alertType !== "DeleteDM" &&
            x?.alertType !== "UnBlocked" &&
            x?.alertType !== "Block" &&
            x?.alertType !== "UnBlock" &&
            x.alertType !== 'EntityUpdate' &&
            x.alertType !== 'DeletePost' &&
            x?.alertType !== "NewRequest" &&
            x?.alertType !== "Rejected" &&
            x?.wardName === undefined &&
            x?.alertType !== "NewRequestToStaff" &&
            x?.alertType !== "Accepted" &&
            x?.data?.type !== "Follow" &&
            x?.alertType !== "Dependant";

        var filteredAlerts = useralertList?.filter( ( x : any ) =>
            loginLink ? filterdatalist( x ) : ( filterdatalist( x ) && x?.alertType !== "NewDM-Ward" && x?.alertType !== "ReplyDM-Ward" &&
                x?.alertType !== "NewRequest-Ward" ),
        );
        var selfUpdatePost = filteredAlerts?.filter( ( x : any ) => ( x?.alertType === "UpdatePost" || x?.alertType === "NewPost" ) && x?.data?.person?.id === currentUser?.id );
        var selfConvo = filteredAlerts.filter( ( x : any ) => x?.alertType === "NewDM" && x?.data?.person?.id === currentUser?.id );
        filteredAlerts = filteredAlerts?.filter( ( item1 : any ) => !selfUpdatePost?.some( ( item2 : any ) => item2?.id === item1?.id ) );
        filteredAlerts = filteredAlerts?.filter( ( item1 : any ) => !selfConvo?.some( ( item2 : any ) => item2?.id === item1?.id ) );
        let unreadAlertData = filteredAlerts?.filter( ( x : any ) => x?.actionTaken === undefined );
        setAllAlertData( filteredAlerts );

    }, [ usernewalert ] );

    function handleConvoDelete ( list : any, usernewalert : any )
    {
        var currentChat = list?.find( ( x : any ) => x?.id === usernewalert?.data?.feed?.id );
        if ( currentChat )
        {
            currentChat.posts = currentChat?.posts?.filter( ( x : any ) => x?.id !== usernewalert?.target?.id );
            currentChat.posts = currentChat?.posts?.filter( ( x : any ) => x?.parentPost
                !== usernewalert?.target?.id );
            list[ list.indexOf( currentChat ) ] = currentChat
            dispatch( setConvo( list ) );
        }
    }


    const [ unread, setunread ] = React.useState(
        useralertList?.filter(
            ( x ) =>
                x.actionTaken === undefined &&
                x.alertType !== 'EntityUpdate' &&
                x.alertType !== 'DeletePost' &&
                x.alertType !== 'NewRequest' &&
                x.alertType !== 'NewRequestToStaff' &&
                x.alertType !== 'NewRequestToStaff' &&
                x.alertType !== 'Follow' &&
                x.alertType !== 'NewRequest-Ward' &&
                x?.data?.type !== 'Dependant' &&
                x?.alertType !== 'Dependant' &&
                x?.wardName === undefined &&
                x?.alertType !== 'UnBlock' &&
                x?.alertType !== 'Block' &&
                x?.alertType !== 'UnBlock' &&
                x?.alertType !== 'Block' &&
                x?.alertType !== 'UnBlocked' &&
                x?.alertType !== 'Blocked' &&
                x?.alertType !== 'DeleteDM'
        )
    );
    const [ all ] = React.useState(
        useralertList?.filter(
            ( x ) =>
                x.alertType !== 'EntityUpdate' &&
                x.alertType !== 'DeletePost' &&
                x.alertType !== 'NewRequest' &&
                x.alertType !== 'NewRequestToStaff' &&
                x.alertType !== 'Follow' &&
                x.alertType !== 'NewRequest-Ward' &&
                x?.data?.type !== 'Dependant' &&
                x?.alertType !== 'Dependant' &&
                x?.wardName == undefined &&
                x?.alertType !== 'UnBlock' &&
                x?.alertType !== 'Block' &&
                x?.alertType !== 'UnBlock' &&
                x?.alertType !== 'Block' &&
                x?.alertType !== 'UnBlocked' &&
                x?.alertType !== 'Blocked' &&
                x?.alertType !== 'DeleteDM'
        )
    );
    const {
        showAlertModal,
        setShowAlertModal,
        handleSaveAlert,
        handleAlert,
        showFollowingModal,
        setShowFollowingModal,
        handleDeleteAll,
        Loader, setisAcceptAll, isAcceptAll, isRejectAll, setisRejectAll, disable
    } = useAlerts();

    //Bind Create convo modal
    const {
        textSearchRef,
        mdlCreateConvoVisible,
        setMdlCreateConvoVisible,
        toFK,
        searchInput,
        filteredResults,
        showSearch,
        showFilePicker,
        handleCreateModel,
        searchItems,
        handleSentToProfile,
        handleSendMessage,
        setConvoLoading,
        isLoading,
        handleSubject,
        subject,
        handleMessage,
        handleRemoveItem,
        getImageGalleryFromFilePickerCreateConvo,
        mdlConvoVisible,
        convoSubject,
        setMdlConvoVisible,
        convoPost,
        convo,
        showConvoFilePicker,
        handleGetConvo,
        handleSendConvoMessage,
        replyValue,
        setreplyValue,
        handleChatMessage,
        media,
        value,
        profile,
        setValue,
        Loading,
        images,
        setImages,
        videoUrls,
        setvideos,
        handleImages,
        handleFilePicker,
        onRefresh,
        setSubject,
        settoFK,
        handlePublish,
        setIsHide,
        isHide,
        isImageProcessing,
        setMedia,
        refConvoList,
        setShowConvoFilePicker,
        HandleEditSet,
        handleUpdatePost,
        itemEdit,
        editingImage,
        setEditingImage,
        setConvoPost,
        scrollToMessageWithDelay,
        fetchloading,
        setSearchInput,
        setShowSearch,
        imageInputRef,
        isTyping,
        typingUserName
    } = useConvoChat();


    React.useEffect( () =>
    {
        let img : any = [];
        let videoUrl : any = [];
        let docsUrl : any = [];
        if ( itemEdit?.media?.type?.startsWith( 'image' ) ) img.push( itemEdit.media );
        else if ( itemEdit?.media?.type?.startsWith( 'video' ) ) videoUrl.push( itemEdit.media );
        else if ( itemEdit?.media?.type?.startsWith( 'application' ) ) docsUrl.push( itemEdit.media );

        itemEdit?.galleryPost?.map( ( item1 ) =>
        {
            if ( item1?.media?.type?.startsWith( 'image' ) ) img.push( item1.media );
            else if ( item1?.media?.type?.startsWith( 'video' ) ) videoUrl.push( item1.media );
            else if ( item1?.media?.type?.startsWith( 'application' ) ) docsUrl.push( item1.media );
        } );
        if ( img?.length > 0 )
        {
            setShowConvoFilePicker( true )
            setEditingImage( true )
            setImages( img );
        }
        if ( videoUrl?.length > 0 )
        {
            setShowConvoFilePicker( true )
            setEditingImage( true )
            setvideos( videoUrl );
        }
    }, [ itemEdit ] );

    // Calculate length based on itemHeights array
    const getItemLayout = ( data, index ) => ( {
        length: itemHeights[ index ] || 0, // Use itemHeights[index], if available, otherwise default to 0
        offset: itemHeights.slice( 0, index ).reduce( ( acc, height ) => acc + height, 0 ),
        index,
    } );

    const [ itemHeights, setItemHeights ] = useState( [] );
    const [ setConvoImage ] = React.useState();
    const [ showPreview, setShowPreview ] = useState( false );
    const [ isDisable, setIsDisable ] = useState( false );


    const handleAlertRedirect = ( item : any ) =>
    {
        if ( item?.actionTaken == undefined || item?.actionTaken !== 'Viewed' )
        {
            handleSaveAlert( item, 'Viewed' );
            item.actionTaken = 'Viewed';
            // Ensure 'unreadAlertData' and 'item.id' exist before accessing 'id'
            setUnreadAlertData( unreadAlertData?.filter( ( x : any ) => x?.id !== item?.id ) );
            let alerts = useralertList.filter( ( x : any ) => x?.id == item?.id );
            useralertList[ useralertList.indexOf( alerts[ 0 ] ) ] = item;
            let useralelist = _.uniqBy( useralertList, 'id' );
            dispatch( setAlerts( useralelist ) );
        }
        item?.target?.type == 'Club' || item?.target?.type == 'School'
            ? AV.AVEntity.get( item?.target ).then( ( response ) =>
                navigate( `/season/${ item?.target?.id }/${ item?.target?.region }`, {
                    state: { isCreate: false, team: item?.target },
                } )
            )
            : item?.target?.type == 'Event'
                ? AV.AVEntity.get( item?.target ).then( ( response ) =>
                    navigate( `/season/${ item?.target?.id }/${ item?.target?.region }`, {
                        state: { isCreate: false, team: item?.target },
                    } )
                )
                : item?.target?.type == 'LeagueTeam'
                    ? AV.AVEntity.get( item?.target ).then( ( response ) =>
                        navigate( `/season/${ item?.target?.id }/${ item?.target?.region }`, {
                            state: { isCreate: false, team: item?.target },
                        } )
                    )
                    : item?.data?.type == 'Staff'
                        ? navigate( `/teams/${ item?.target?.id }/${ item?.target?.region }`, {
                            state: { isCreate: false, team: item?.target },
                        } )
                        : item?.data?.feed?.type == 'Convo'
                            ? messagealert( item )
                            : item?.alertType === 'Impression' ||
                                item?.alertType === 'PostReply' ||
                                item?.alertType === 'PostReview' ||
                                item?.target?.type === 'Post' ||
                                item?.alertType === 'NewPost' ||
                                item?.alertType === 'UpdatePost'
                                ? handlePost( item )
                                : navigate( `/profile/${ item?.target?.id }/${ item?.target?.region }`, {
                                    state: { user: item?.target },
                                } );
    };

    async function updateUnreadMessages ( item )
    {
        let id = item?.id;
        const UnReadChatMessage = useralertList?.filter( ( x : any ) => x?.actionTaken == undefined && x?.data?.feed?.id === id && ( x?.alertType === "NewDM" || x?.alertType === "ReplyDM" ) );

        if ( UnReadChatMessage?.length > 0 )
        {
            for ( const message of useralertList )
            {
                if ( message?.data?.feed?.id === id && message?.actionTaken !== "Viewed" && ( message?.alertType === "NewDM" || message?.alertType === "ReplyDM" ) )
                {
                    message.actionTaken = 'Viewed';
                }
            }
            dispatch( setAlerts( useralertList ) );
            for ( let i = 0; i < UnReadChatMessage.length; i++ )
            {
                await Alert.Save( UnReadChatMessage[ i ] );
            }
        }
    }

    const messagealert = ( convo ) =>
    {
        setShowAlertModal( false );
        updateUnreadMessages( convo )
        handleGetConvo( convo );
    };

    const [ showProfile, setShowProfile ] = React.useState( false );
    const [ showAddinviteModal, setshowAddinviteModal ] = React.useState( false );
    const [ personname, setpersonname ] = React.useState<string>();
    const [ personEmail, setpersonEmail ] = React.useState<string>();
    const [ personPhone, setpersonPhone ] = React.useState<string>();
    const [ isrequest ] = React.useState( true );
    const [ ismanagerequest ] = React.useState( false );
    const [ isunread, setIsUnread ] = React.useState( true );
    const [ isall, setIsAll ] = React.useState( false );
    const [ valuess, setvalues ] = React.useState<any>();
    const [ isCreateNew, setIsCreateNew ] = React.useState( true );
    const [ isCreate, setIsCreate ] = React.useState( false );
    const replaceChildrenInParent = ( postId, parentpostId, nestedData, newChildren ) =>
    {
        for ( const item of nestedData )
        {
            if ( item.id === parentpostId )
            {
                if ( !item?.children ) item.children = [];
                let val = item.children.filter( ( x ) => x?.id == postId );
                if ( val?.length > 0 ) item.children[ item.children.indexOf( val[ 0 ] ) ] = newChildren;
                else item.children.push( newChildren );
                return item;
            }

            if ( item.children && item.children.length > 0 )
            {
                // Continue searching in children
                const updatedChild = replaceChildrenInParent(
                    postId,
                    parentpostId,
                    item.children,
                    newChildren
                );
                if ( updatedChild )
                {
                    return item; // Return the parent with updated children
                }
            }
            if ( item?.galleryPost && item.galleryPost.length > 0 )
            {
                // Continue searching in children
                const updatedChild = replaceChildrenInParent(
                    postId,
                    parentpostId,
                    item.galleryPost,
                    newChildren
                );
                if ( updatedChild )
                {
                    return item; // Return the parent with updated children
                }
            }
        }
        return null; // Parent post not found
    };

    const handlePost = async ( item ) =>
    {
        if ( item?.data?.isConvo )
        {
            let convopost = userconvo?.filter( ( x : any ) => x?.id === item?.target?.pk );
            if ( convopost?.length > 0 )
            {
                messagealert( convopost[ 0 ] )
                scrollToMessageWithDelay( item, convopost[ 0 ] )
            }
        }
        else
        {
            setvalues( item );
            if ( item?.alertType === 'PostReply' )
            {
                let post = await AV.Post.get( item?.target );
                let ppost = userFeeds?.filter( ( x ) => x.id == post?.parentPost );
                let replypost = ppost[ 0 ]?.children?.filter( ( x ) => x.id === item?.target?.id );
                if ( ppost?.length > 0 )
                {
                    navigate( '/', { state: { previewdata: ppost[ 0 ] } } );
                }
            }
            else if ( item?.alertType === 'Impression' )
            {
                let post = userFeeds?.filter( ( x : any ) => x?.id === item?.target?.id );
                if ( post?.length === 0 || post == undefined )
                {
                    post = await AV.Post.get( item?.target );
                    const updatedParentPost = replaceChildrenInParent(
                        post?.id,
                        post?.parentPost,
                        userFeeds,
                        post
                    );
                    if ( updatedParentPost !== null )
                    {
                        navigate( '/', { state: { previewdata: post } } );
                    }
                } else navigate( '/', { state: { previewdata: post?.length > 0 ? post[ 0 ] : item } } );
            }
            else
            {
                let post = await AV.Post.get( item?.target );
                const updatedParentPost = replaceChildrenInParent(
                    post?.id,
                    post?.parentPost,
                    userFeeds,
                    post
                );
                navigate( '/', { state: { previewdata: updatedParentPost ? updatedParentPost : post } } );
            }

        }
        if ( item?.alertType === 'Impression' )
        {
            let post = userFeeds?.filter( ( x : any ) => x?.id === item?.target?.id );
            if ( post?.length === 0 || post == undefined )
            {
                post = await AV.Post.get( item?.target );
                const updatedParentPost = replaceChildrenInParent(
                    post?.id,
                    post?.parentPost,
                    userFeeds,
                    post
                );
                if ( updatedParentPost )
                {
                    navigate( '/', { state: { previewdata: updatedParentPost ? updatedParentPost : post } } );
                }
            } else navigate( '/', { state: { previewdata: post?.length > 0 ? post[ 0 ] : item } } );
        }
        setShowAlertModal( false );
    };
    const [ showEmoji, setShowEmoji ] = React.useState( false );


    const onEmojiClick = ( event, emojiObject ) =>
    {
        const newVal = value + emojiObject.emoji;
        setValue( newVal );
        setShowEmoji( false );
    };
    const commentValueRef : any = useRef( null );
    useEffect( () =>
    {
        if ( commentValueRef.current )
        {
            commentValueRef.current.focus();
        }
    }, [] );
    const menuRef = useRef( null );
    useOutsideAlerter( menuRef );
    //Hook that alerts clicks outside of the passed ref
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            // Alert if clicked on outside of element
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
                    setShowProfile( false );
                }
            }
            // Bind the event listener
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                // Unbind the event listener on clean up
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }
    const searchfollowing = async ( val, isrequest ) =>
    {
        if ( isrequest )
        {
            let requestval = _.orderBy(
                customUniq( [
                    ...( followRequestData || [] ),
                    ...( currentUser?.requests?.filter( ( x ) => x?.actionTaken === undefined ) || [] ),
                    ...( followreqlist || [] ),
                ] ),
                [ 'date' ],
                [ 'desc' ]
            );
            setrequestdata(
                requestval?.filter(
                    ( x ) =>
                        ( x?.target?.title !== undefined &&
                            x?.target?.title?.toLocaleLowerCase().includes( val.toLocaleLowerCase() ) ) ||
                        ( x?.source?.title !== undefined &&
                            x?.source?.title?.toLocaleLowerCase().includes( val.toLocaleLowerCase() ) ) ||
                        ( x?.title !== undefined &&
                            x?.title?.toLocaleLowerCase().includes( val.toLocaleLowerCase() ) )
                )
            );
        } else
        {
            setManageRequestdata(
                currentUser?.follows.filter(
                    ( x ) =>
                        ( x?.target?.title !== undefined &&
                            x?.target?.title?.toLocaleLowerCase().includes( val.toLocaleLowerCase() ) ) ||
                        ( x?.title !== undefined &&
                            x?.title?.toLocaleLowerCase().includes( val.toLocaleLowerCase() ) )
                )
            );
        }
    };
    const UnReadChatMessage = useralertList?.filter( ( x : any ) => x?.actionTaken == undefined && ( x?.alertType == "NewDM" || x?.alertType == "ReplyDM" ) && x?.data?.person?.id !== currentUser?.id );

    const RenderalertTab = useCallback(
        ( alert ) =>
        {
            let item = alert.item;
            return (
                <VStack>
                    <Pressable
                        _hover={ { backgroundColor: 'gray.100' } }
                        width={ '100%' }
                        height={ '104px' }
                        justifyContent={ 'center' }
                        onPress={ () =>
                        {
                            handleAlertRedirect( item );
                        } }
                    >
                        <HStack>
                            <HStack width={ '100%' }>
                                { ( item?.alertType === 'NewEvent' ||
                                    item?.alertType === 'DelResource' ||
                                    item?.alertType === 'UpdResource' ||
                                    item?.alertType === 'UpdateEvent' ||
                                    item?.alertType === 'CancelEvent' ||
                                    item?.alertType === 'NewResource' ) && (
                                        <>
                                            <HStack space={ '12px' }>
                                                <ProfilePic width={ '56px' } height={ '56px' } link={ item?.target } />
                                                <VStack width={ '100%' }>
                                                    <Pressable
                                                        onPress={ () =>
                                                        {
                                                            handleAlertRedirect( item );
                                                            setConvoLoading( false );
                                                            setValue( '' );
                                                        } }
                                                    >
                                                        <Text
                                                            color={ 'black' }
                                                            fontSize={ '18px' }
                                                            width={ '70%' }
                                                            fontWeight={ 500 }
                                                            text-overflow={ 'ellipsis' }
                                                            numberOfLines={ 1 }
                                                            textTransform={ 'capitalize' }
                                                        >
                                                            { item?.target?.title }
                                                        </Text>
                                                    </Pressable>
                                                    <Text fontSize={ '16px' } color={ 'gray.400' }>
                                                        { item?.alertType === 'NewEvent'
                                                            ? Loc.currentLang.alertType.newEvent
                                                            : item?.alertType === 'DelResource'
                                                                ? Loc.currentLang.alertType.delResource
                                                                : item?.alertType === 'UpdResource'
                                                                    ? Loc.currentLang.alertType.updResource
                                                                    : item?.alertType === 'UpdateEvent'
                                                                        ? Loc.currentLang.alertType.updateEvent
                                                                        : item?.alertType === 'CancelEvent'
                                                                            ? Loc.currentLang.alertType.cancelEvent
                                                                            : Loc.currentLang.alertType.newResource }
                                                    </Text>
                                                    <Text fontSize={ '16px' } color={ 'gray.400' }>
                                                        { Moment( item?.date ).fromNow() }..
                                                    </Text>
                                                </VStack>
                                            </HStack>
                                        </>
                                    ) }
                                { ( item?.alertType === 'PostReview' || item?.alertType === 'NewPost' || item?.alertType === 'UpdatePost' ) && (
                                    <>
                                        <TouchableOpacity
                                            delayPressIn={ 0 }
                                            onPress={ () =>
                                            {
                                                handleAlertRedirect( item );
                                            } }
                                        >
                                            <HStack space={ '12px' }>
                                                <ProfilePic
                                                    width={ '56px' } height={ '56px' }
                                                    link={
                                                        item?.target?.thumb
                                                            ? item?.target
                                                            : item?.feed?.type == 'Person' ? item?.data?.person
                                                                : ""
                                                    }
                                                />

                                                <VStack width={ '100%' }>
                                                    <Pressable
                                                        onPress={ () =>
                                                        {
                                                            handleAlertRedirect( item );
                                                        } }
                                                    >
                                                        <Text
                                                            color={ 'black' }
                                                            fontSize={ '18px' }
                                                            fontWeight={ 500 }
                                                            text-overflow={ 'ellipsis' }
                                                            width={ '70%' }
                                                            numberOfLines={ 1 }
                                                            textTransform={ 'capitalize' }
                                                        >
                                                            { item?.data?.feed?.title
                                                                ? item?.data?.feed?.title
                                                                : item?.target?.title }
                                                        </Text>
                                                    </Pressable>
                                                    <Text fontSize={ '16px' } color={ 'gray.400' }>
                                                        { item?.alertType === 'PostReview'
                                                            ? Loc.currentLang.alertType.postReview : item?.alertType === "NewPost" ?
                                                                Loc.currentLang.alertType.newPost : item?.alertType }
                                                    </Text>
                                                    <Text fontSize={ '16px' } color={ 'gray.400' }>
                                                        { Moment( item?.date ).fromNow() }
                                                    </Text>
                                                </VStack>
                                            </HStack>
                                        </TouchableOpacity>
                                    </>
                                ) }
                                { ( item?.alertType === 'NewDM' ||
                                    item?.alertType === 'ReplyDM' ||
                                    item?.alertType === 'NewDM-Ward' ||
                                    item?.alertType === 'ReplyDM-Ward' ) && (
                                        <>
                                            <TouchableOpacity
                                                delayPressIn={ 0 }
                                                onPress={ () =>
                                                {
                                                    handleAlertRedirect( item );
                                                } }
                                            >
                                                <HStack space={ '12px' }>
                                                    <ProfilePic
                                                        width={ '56px' } height={ '56px' }
                                                        link={ item?.data?.person ? item?.data?.person : item?.data?.source }
                                                    />
                                                    <VStack width={ '100%' }>
                                                        {/* <Tooltip label={ "New message" } openDelay={ 500 }>*/ }
                                                        <Pressable
                                                            onPress={ () =>
                                                            {
                                                                handleAlertRedirect( item );
                                                            } }
                                                        >
                                                            <Text
                                                                color={ 'black' }
                                                                fontSize={ '18px' }
                                                                fontWeight={ 500 }
                                                                width={ '70%' }
                                                                text-overflow={ 'ellipsis' }
                                                                numberOfLines={ 1 }
                                                                textTransform={ 'capitalize' }
                                                            >
                                                                { item?.alertType === 'NewDM'
                                                                    ? Loc.currentLang.message.newMessage
                                                                    : Loc.currentLang.message.replyMessage }
                                                            </Text>
                                                        </Pressable>
                                                        {/*</Tooltip>*/ }
                                                        <Text fontSize={ '16px' } color={ 'gray.400' }>
                                                            {
                                                                convoListData
                                                                    ?.filter( ( x ) => x.id == item?.data?.feed?.id )[ 0 ]
                                                                    ?.posts?.filter( ( x ) => x.id == item?.target?.id )[ 0 ]?.text
                                                            }
                                                        </Text>
                                                        <Text fontSize={ '16px' } color={ 'gray.400' }>
                                                            { Moment( item?.date ).fromNow() }
                                                        </Text>
                                                    </VStack>
                                                </HStack>
                                            </TouchableOpacity>
                                        </>
                                    ) }
                                { item?.alertType === 'PostReply' && (
                                    <>
                                        <TouchableOpacity
                                            delayPressIn={ 0 }
                                            onPress={ () =>
                                            {
                                                handleAlertRedirect( item );
                                            } }
                                        >
                                            <HStack space={ '12px' }>
                                                <ProfilePic
                                                    width={ '56px' } height={ '56px' }
                                                    link={
                                                        item?.data?.person ?
                                                            item?.data?.person :
                                                            item?.target?.thumb
                                                                ? item?.target
                                                                : item?.data?.source
                                                    }
                                                />
                                                <VStack width={ '100%' }>
                                                    {/* <Tooltip label={ item?.data?.person?.title ? item?.data?.person?.title : item?.data?.source?.title } openDelay={ 500 }>*/ }
                                                    <Pressable
                                                        onPress={ () =>
                                                        {
                                                            handleAlertRedirect( item );
                                                        } }
                                                    >
                                                        <Text
                                                            color={ 'black' }
                                                            fontSize={ '18px' }
                                                            width={ '70%' }
                                                            fontWeight={ 500 }
                                                            text-overflow={ 'ellipsis' }
                                                            numberOfLines={ 1 }
                                                            textTransform={ 'capitalize' }
                                                        >
                                                            { item?.data?.person?.title
                                                                ? item?.data?.person?.title
                                                                : item?.data?.source?.title }
                                                        </Text>
                                                    </Pressable>
                                                    {/* </Tooltip>*/ }
                                                    <Text fontSize={ '16px' } color={ 'gray.400' }>
                                                        { item?.data?.source?.type ? item?.data?.source?.type : 'Comments on' } { item?.data?.isComment ? " Your Comment" : item?.data?.isConvo ? " Your Message" : " your post" }
                                                    </Text>
                                                    <Text fontSize={ '16px' } color={ 'gray.400' }>
                                                        { Moment( item?.date ).fromNow() }
                                                    </Text>
                                                </VStack>
                                            </HStack>
                                        </TouchableOpacity>
                                    </>
                                ) }
                                { item?.alertType === 'Impression' && (
                                    <>
                                        <TouchableOpacity
                                            delayPressIn={ 0 }
                                            onPress={ () =>
                                            {
                                                handleAlertRedirect( item );
                                            } }
                                        >
                                            <HStack space={ '12px' }>
                                                <ProfilePic
                                                    width={ '56px' } height={ '56px' }
                                                    link={
                                                        item?.data?.source
                                                    }
                                                />
                                                <VStack width={ '100%' }>
                                                    {/* <Tooltip label={ item?.data?.person?.title ? item?.data?.person?.title : item?.data?.source?.title } openDelay={ 500 }>*/ }
                                                    <Pressable
                                                        onPress={ () =>
                                                        {
                                                            handleAlertRedirect( item );
                                                        } }
                                                    >
                                                        <Text
                                                            color={ 'black' }
                                                            fontSize={ '18px' }
                                                            width={ '70%' }
                                                            fontWeight={ 500 }
                                                            text-overflow={ 'ellipsis' }
                                                            numberOfLines={ 1 }
                                                            textTransform={ 'capitalize' }
                                                        >
                                                            { item?.data?.person?.title
                                                                ? item?.data?.person?.title
                                                                : item?.data?.source?.title }
                                                        </Text>
                                                    </Pressable>
                                                    {/* </Tooltip>*/ }
                                                    <Text fontSize={ '16px' } color={ 'gray.400' }>
                                                        { item?.data?.isConvo ? item?.data?.source?.title + " React on you Message" : item?.data?.source?.title + " React on you post" }
                                                    </Text>
                                                    <Text fontSize={ '16px' } color={ 'gray.400' }>
                                                        { Moment( item?.date ).fromNow() }
                                                    </Text>
                                                </VStack>
                                            </HStack>
                                        </TouchableOpacity>
                                    </>
                                ) }
                                { item?.alertType !== 'PostReview' &&
                                    item?.alertType !== 'CancelEvent' &&
                                    item?.alertType !== 'NewEvent' &&
                                    item?.alertType !== 'DelResource' &&
                                    item?.alertType !== 'Impression' &&
                                    item?.alertType !== 'UpdateEvent' &&
                                    item?.alertType !== 'NewPost' && item?.alertType !== 'UpdatePost' &&
                                    item?.alertType !== 'PostReply' &&
                                    item?.alertType !== 'UpdResource' &&
                                    item?.alertType !== 'NewDM' &&
                                    item?.alertType !== 'ReplyDM' &&
                                    item?.alertType !== undefined &&
                                    item?.alertType !== 'ReplyDM-Ward' &&
                                    item?.alertType !== 'EntityUpdate' &&
                                    item?.alertType !== 'NewResource' &&
                                    item?.alertType !== 'NewDM-Ward' && (
                                        <>
                                            <TouchableOpacity
                                                delayPressIn={ 0 }
                                                onPress={ () =>
                                                {
                                                    handleAlertRedirect( item );
                                                } }
                                            >
                                                <HStack space={ '12px' }>
                                                    <ProfilePic
                                                        width={ '56px' } height={ '56px' }
                                                        link={ item?.data?.source ? item?.data?.source : item?.target }
                                                    />
                                                    <VStack width={ '100%' }>
                                                        {/* <Tooltip label={ item?.target?.title } openDelay={ 500 }>*/ }
                                                        <Pressable
                                                            onPress={ () =>
                                                            {
                                                                handleAlertRedirect( item );
                                                            } }
                                                        >
                                                            <Text
                                                                color={ 'black' }
                                                                fontSize={ '18px' }
                                                                fontWeight={ 500 }
                                                                width={ '70%' }
                                                                text-overflow={ 'ellipsis' }
                                                                numberOfLines={ 1 }
                                                                textTransform={ 'capitalize' }
                                                            >
                                                                { item?.target?.title }
                                                            </Text>
                                                        </Pressable>
                                                        {/*</Tooltip>*/ }
                                                        <Text color={ 'gray.400' } fontSize={ '16px' }>
                                                            { item?.alertType ? item?.alertType : item?.data?.type }{ ' ' }
                                                        </Text>
                                                        <Text color={ 'gray.400' } fontSize={ '16px' }>
                                                            { Moment( item?.date ).fromNow() }
                                                        </Text>
                                                    </VStack>
                                                </HStack>
                                            </TouchableOpacity>
                                        </>
                                    ) }
                            </HStack>
                            <HStack alignSelf={ 'flex-end' } right={ 10 } bottom={ '8px' }>
                                { ( item?.alertType === 'NewRequest' || item?.alertType === 'NewRequestToStaff' ) &&
                                    item?.actionTaken !== 'Accepted' &&
                                    item?.actionTaken !== 'Rejected' && (
                                        <VStack space={ 1 } width={ '15%' }>
                                            <Button
                                                p={ 1 }
                                                bgColor={
                                                    item === location?.state?.alert && location?.state?.type === 'Accepted'
                                                        ? 'AVColor.primary'
                                                        : '#eeeeee'
                                                }
                                                borderRadius={ 50 }
                                                height='max-content'
                                                width='min-content'
                                                margin-left='100%'
                                                isDisabled={ isLoading }
                                                onPress={ () => handleAlert( item, 'Accepted' )
                                                }
                                                isLoading={
                                                    item === location?.state?.alert && location?.state?.type === 'Accepted'
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <TickIcon size='md' color='#32AE78' />
                                            </Button>
                                            <Button
                                                p={ 1 }
                                                bgColor={
                                                    item === location?.state?.alert && location?.state?.type === 'Rejected'
                                                        ? 'AVColor.primary'
                                                        : '#eeeeee'
                                                }
                                                borderRadius={ 50 }
                                                height='max-content'
                                                width='min-content'
                                                margin-left='100%'
                                                isDisabled={ isLoading }
                                                onPress={ () => handleAlert( item, 'Rejected' ) }
                                                isLoading={
                                                    item === location?.state?.alert && location?.state?.type === 'Rejected'
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <CrossIcon size='20px' color='#E02020' />
                                            </Button>
                                        </VStack>
                                    ) }
                            </HStack>
                        </HStack>
                    </Pressable>
                    <Divider />
                </VStack>
            );
        },
        [ allAlertData, unreadAlertData ]
    );

    const handleuserRedirect = async ( profile ) =>
    {
        navigate( `/profile/${ profile?.id }/${ profile?.region }`, { state: { user: profile } } );
        setMdlCreateConvoVisible( !mdlCreateConvoVisible );
    };

    const isMobile = props?.dimensions?.width <= 600 || false;
    const isHeaderResponsive = props?.dimensions?.width <= 1000 || false;
    const [ showBurgerMenu, setShowBurgerMenu ] = React.useState( false );
    const userisguardian = stateUser?.user?.userisguardian;
    const loginLink = stateUser?.user?.loginLink;
    const [ isOpen, setIsOpen ] = React.useState( true );
    const toastShow = useSelector( ( state : RootStateOrAny ) => state?.user?.toastShow );
    const [ previewitem, setpreviewitem ] = useState()
    const [ isHeader, setHeader ] = useState( false );
    const deviceFCMToken = useSelector( ( state : RootStateOrAny ) => state.user.devicefcmtoken );
    const clearCache = useClearCache();

    const handleLogout = async () =>
    {
        try
        {
            if ( deviceFCMToken?.length > 0 )
            {
                await Person.removeFCMToken( deviceFCMToken );
            }

            // Clear the cache without reloading the Window
            await clearCache( false );

            console.log( "Log out clearance is done!" );
            AVAuth.logout();
        } catch ( error )
        {
            console.error( "Error during logout process:", error );
            // Handle the error appropriately, e.g., show a notification to the user
        }
    };

    const handleMessageIconClick = async () =>
    {
        if ( !showConvoListModal )
        {
            setShowConvoListModal( true );
            setShowAlertModal( false );
            setShowFollowingModal( false );
            setShowProfile( false );

            if ( currentUser.fcmTokens?.filter( x => x === deviceFCMToken ).length === 0 || currentUser?.fcm?.length === 0 )
            {
                AV.Person.addFCMToken( deviceFCMToken );
                getConvo();
            }
        }
    }

    const getConvo = async () =>
    {
        let convovalue : AV.IConversation[] = [];
        let r = 0;
        let person : AV.IPerson = await AV.Person.getSelf();
        let ConvovalList = convoval;
        if ( person.convos !== undefined )
        {
            for ( let i = 0; i < person.convos.length; i++ )
            {
                let param : AV.IFeedParam = {
                    feed: person?.convos[ i ], checkArchive: false,
                    startDate: new Date( ConvovalList?.length > 0 ? ConvovalList[ 0 ]?.lastUpdated : 0 ),
                    forward: true
                };

                // Create a function to handle the delay
                const delay = ( ms ) => new Promise( resolve => setTimeout( resolve, ms ) );

                // eslint-disable-next-line no-loop-func
                let data : any = {
                    Ifeed: param,
                    lasttime: new Date()
                };
                lastfeed?.push( data )
                dispatch( setLastFeedUpdate( lastfeed ) );
                setlastfeed( lastUpdateFeed )
                await AV.Feed.getFeed( param ).then( ( response : any ) =>
                {

                    let PostList = AV.Feed.getPostList( response );
                    if ( PostList?.length > 0 )
                    {
                        AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList?.slice( 0, 10 ) ).then( ( x : any ) =>
                        {
                            if ( x?.length > 0 )
                            {
                                x.sort( ( a : any, b : any ) =>
                                {
                                    const createDateA = new Date( a.createDate ) as any;
                                    const createDateB = new Date( b.createDate ) as any;
                                    return createDateB - createDateA;
                                } );
                                response.posts = x;
                                if ( response?.posts !== undefined && response?.posts?.length > 0 ) convovalue.push( response );
                                if ( person?.convos !== undefined && r === person?.convos?.length )
                                {
                                    Convoupdate( convovalue, ConvovalList );
                                }
                            }
                        } );
                    }
                    r++;
                } );

                // Add 10 seconds delay
                await delay( 100 );
            }
        }
    };

    const Convoupdate = ( convovalue, ConvovalList ) =>
    {
        if ( ConvovalList?.length === 0 || ConvovalList === undefined || ConvovalList === null ) ConvovalList = [];
        for ( let i = 0; i < convovalue?.length; i++ )
        {
            let excitingconvo = ConvovalList?.filter( ( x ) => x?.id == convovalue[ i ]?.id );
            if ( excitingconvo?.length > 0 )
            {
                for ( let j = 0; j < convovalue[ i ]?.posts?.length; j++ )
                {
                    let excitingconvopost = excitingconvo[ 0 ]?.posts?.filter(
                        ( x ) => x?.id === convovalue[ i ]?.posts[ j ]?.id
                    );
                    if ( excitingconvopost?.length === 0 )
                    {
                        excitingconvo[ 0 ]?.posts.push( convovalue[ i ]?.posts[ j ] );
                    }
                }
            } else ConvovalList.unshift( convovalue[ i ] );
        }
        ConvovalList = _.uniqBy( ConvovalList, 'id' );
        dispatch( setConvo( ConvovalList ) );
    };

    const HandleRemoveFollowRequestData = ( item ) =>
    {
        setrequestdata( requestdata.filter( ( f : any ) => f?.id != item.id ) )
        setrequestdata1( requestdata.filter( ( f : any ) => f?.id != item.id ) )
        dispatch( setFollowRequestData( followRequestData.filter( ( f : any ) => f?.id != item.id ) ) );
    }

    const handleCancelAlert = () =>
    {
        setShowAlertModal( false );
        setShowFollowingModal( false );
        setShowConvoListModal( false );
        setMdlConvoVisible( false );
        setMdlCreateConvoVisible( false );
        setConvoLoading( false );
    };
    const isDesktopView = props?.dimensions?.width <= 1000 || false;
    const googlePlayUrl : any = 'https://play.google.com/store/apps/details?id=com.actavivo';
    const appStoreUrl : any = 'https://apps.apple.com/app/actavivo/id1611252168';

    const redirectToApp = () =>
    {
        if ( isMobileDevice )
        {
            const isIOS = /iPad|iPhone|iPod/.test( navigator.userAgent );
            const storeUrl = isIOS ? appStoreUrl : googlePlayUrl;
            const androidAppUrl : any = 'actavivo://actavivo.com';
            if ( !isIOS )
            {
                try
                {
                    window.location = androidAppUrl;
                    setTimeout( function ()
                    {
                        window.location = storeUrl;
                    }, 3000 );
                } catch ( e : any )
                {
                    throw new Error( e );
                }
            } else
            {
                // Try to open the app
                window.location.href = storeUrl;
            }
        }
    };

    const OpenNotification = () =>
    {
        setShowAlertModal( true );
        setShowFollowingModal( false );
        setShowConvoListModal( false );
        setShowProfile( false );
        setShowBurgerMenu( !showBurgerMenu );
        setShowFollowingModal( false );
        setShowConvoListModal( false );
        setMdlConvoVisible( false );
        setMdlCreateConvoVisible( false );
        setConvoLoading( false );
    }

    const handleuserchange = async ( item ) =>
    {
        if ( !loginLink )
        {
            await Server.impersonate( item, currentUser );
            let user = await Server.GetLoginLink();
            dispatch( setLoginLink( user ) );
            dispatch( setuserisguardian( false ) );
        } else
        {
            await Server.impersonate();
            dispatch( setLoginLink( [] ) );
            dispatch( setuserisguardian( true ) );
        }
        setShowProfile( false );
        await AV.Person.getSelf( true ).then( ( x ) => dispatch( setUser( x ) ) );
        dispatch( setFeeds( [] ) );
        dispatch( setAlerts( [] ) );
        dispatch( setConvo( [] ) );
    };
    const handleAllRequest = async ( type ) =>
    {
        setIsDisable( true );
        if ( type == "Accepted" ) setisAcceptAll( true )
        else setisRejectAll( true );
        for ( var i = 0; i < requestdata.length; i++ )
        {
            await handleAlert( requestdata[ i ], followRequestData?.filter( x => x?.id == requestdata[ i ]?.id )?.length > 0 ? "followback" : type );
        }
        if ( type == "Accepted" ) setisAcceptAll( false )
        else setisRejectAll( false );
        setIsDisable( false );
    };

    const handlePress = ( item ) =>
    {
        setShowFollowingModal( !showFollowingModal );

        const target = item?.source || item?.target;

        if ( target )
        {
            navigate( `/profile/${ target.id }/${ target.region }`, {
                state: {
                    user: {
                        id: target.id,
                        pk: target.pk,
                        region: target.region,
                        type: target.type,
                        name: target.title,
                    },
                },
            } );
        }
    };

    return (
        <>
            <VStack
                width={ '100%' }
                backgroundColor={ '#fff' }
                position={ 'fixed' }
                shadow={ 1 }
                zIndex={ 99 }
                alignItems={ 'center' }
            >
                <AVGradientBox />
                <HStack
                    width={ '100%' }
                    maxWidth={ 'appMaxWidth' }
                    height={ '80px' }
                    px={ isMobile ? '5px' : '32px' }
                >
                    <HStack alignItems={ 'center' } zIndex={ 1 } borderWidth={ 0 } width={ '100%' }>
                        <HStack alignItems={ 'center' } space={ '24px' } width={ isMobile ? '49%' : '33%' }>
                            <Logo />
                            <QuickSearch { ...props } />
                        </HStack>
                        <Hidden only={ [ 'base', 'sm', 'md' ] }>
                            <HStack width={ '33%' } justifyContent={ 'center' }>
                                <Menu { ...props } />
                            </HStack>
                        </Hidden>
                        <HStack
                            alignItems={ 'center' }
                            justifyContent={ 'flex-end' }
                            space={ 3 }
                            borderWidth={ 0 }
                            width={ isMobile ? '49%' : isHeaderResponsive && !isMobile ? '' : '33%' }
                            position={ isHeaderResponsive ? 'absolute' : 'relative' }
                            right={ isHeaderResponsive ? 0 : '' }
                        >
                            <UserMenu
                                isMobile={ isMobile }
                                followreqlist={ followreqlist }
                                unreadAlertData={ unreadAlertData }
                                setShowAlertModal={ setShowAlertModal }
                                setShowFollowingModal={ setShowFollowingModal }
                                setShowConvoListModal={ setShowConvoListModal }
                                showProfile={ showProfile }
                                setShowProfile={ setShowProfile }
                                currentUser={ currentUser }
                                showBurgerMenu={ showBurgerMenu }
                                setShowBurgerMenu={ setShowBurgerMenu }
                                showAddinviteModal={ showAddinviteModal }
                                setshowAddinviteModal={ setshowAddinviteModal }
                                personname={ personname }
                                setpersonname={ setpersonname }
                                personEmail={ personEmail }
                                setpersonEmail={ setpersonEmail }
                                personPhone={ personPhone }
                                setpersonPhone={ setpersonPhone }
                                customUniq={ customUniq }
                                requestdata={ requestdata }
                                convoPost={ undefined }
                                setMdlCreateConvoVisible={ setMdlCreateConvoVisible }
                                setMdlConvoVisible={ setMdlConvoVisible }
                                setConvoLoading={ setConvoLoading }
                                followRequestData={ followRequestData }
                                handleMessageIconClick={ handleMessageIconClick }
                            />
                        </HStack>
                    </HStack>
                    {/*  menu on smaller screen sizes */ }
                    { isDesktopView &&
                        ( <div className='navbar'>
                            <Menu />
                        </div> ) }
                </HStack>
            </VStack>
            { isOpen && isMobileDevice && toastShow && (
                <VStack
                    paddingY={ '10px' }
                    position={ 'fixed' }
                    top={ '84px' }
                    backgroundColor={ 'white' }
                    shadow={ 5 }
                    borderRadius={ '10px' }
                    width={ '100%' }
                    zIndex={ 999 }
                >
                    <HStack marginLeft={ '10px' } space={ 3 }>
                        <Box position={ 'absolute' }>
                            <img src={ LogoSvg } height={ '45px' } width={ '45px' } />
                        </Box>
                        <Text
                            marginLeft={ '45px' }
                            width={ '55%' }
                            fontSize={ '14' }
                            fontWeight={ 'medium' }
                            numberOfLines={ 2 }
                        >
                            { Loc.currentLang.landingPage.appdescription
                                ? Loc.currentLang.landingPage.appdescription
                                : 'Actavivo:Download the mobile app for the best experience!' }
                        </Text>
                        <Button marginRight={ '10px' } bgColor={ 'AVColor.primary' } onPress={ redirectToApp }>
                            { Loc.currentLang.landingPage.openApp ? Loc.currentLang.landingPage.openApp : 'Open' }
                        </Button>
                        <Pressable
                            padding={ 1.5 }
                            rounded={ 'full' }
                            position={ 'absolute' }
                            right={ '3px' }
                            top={ -7 }
                            zIndex={ 999 }
                            _hover={ { backgroundColor: 'AVColor.hovercolor2' } }
                            backgroundColor={ 'AVColor.buttonBg' }
                            onPress={ () =>
                            {
                                setIsOpen( false );
                                dispatch( setToastShow( false ) );
                            } }
                        >
                            <CloseIcon size={ '10px' } color={ 'AVColor.secondaryText' } />
                        </Pressable>
                    </HStack>
                </VStack>
            ) }

            {/* Following Request Screen */ }
            { showFollowingModal && (
                <Box
                    position={ [ 'fixed' ] }
                    top={ [ `${ 83 - 1 }px` ] }
                    right={ [ 0 ] }
                    w={ isMobile ? '100%' : '460px' }
                    h={ '100vh' }
                    bgColor={ [ 'AVColor.white' ] }
                    shadow={ [ 10 ] }
                    borderColor={ 'AVColor.grey' }
                    borderWidth={ 1 }
                    zIndex={ [ 100 ] }
                >
                    <VStack padding={ '24px' } h={ '100%' } position={ 'relative' } >
                        <HStack justifyContent={ 'space-between' }>
                            <Text fontSize={ 24 } fontWeight={ 400 }>
                                { Loc.currentLang.menu.followingRequest }
                            </Text>
                            <HStack alignItems={ [ 'center' ] } space={ '32px' }>
                                <AVCancel callback={ handleCancelAlert } color={ '' } size={ undefined } />
                            </HStack>
                        </HStack>
                        <VStack h={ '100%' } flex={ 1 } zIndex={ -1 }>
                            <Input
                                borderWidth={ 0 }
                                placeholder={
                                    isrequest
                                        ? Loc.currentLang.menu.searchRequests
                                        : Loc.currentLang.menu.searchFollowers
                                }
                                variant='AVSearchInput'
                                backgroundColor={ '#F5F5F5' }
                                marginTop={ '24px' }
                                borderRadius={ '22px' }
                                fontSize={ '16px' }
                                width={ [ '100%' ] }
                                height={ '44px' }
                                InputLeftElement={
                                    <SearchIcon ml='2' size='24px' color='#000' left={ '10px' } p={ '7px' } />
                                }
                                onChangeText={ ( e ) => searchfollowing( e, isrequest ) }
                            />

                            <VStack marginTop={ '10px' }>
                                { isrequest && (
                                    <ScrollView showsHorizontalScrollIndicator={ false }>
                                        <Box overflowY={ 'scroll' } h={ dimensions.height - 310 }>
                                            { requestdata?.length == 0 && <HStack alignItems={ 'center' } justifyContent={ 'center' } marginTop={ '50px' }><Text>No Request Found</Text></HStack> }
                                            { requestdata?.map( ( item, i ) =>
                                            {
                                                Link.getTummb(
                                                    item?.data?.source,
                                                    item?.data?.source?.thumb
                                                );
                                                Link.getTummb(
                                                    item?.source ? item?.source : item?.target,
                                                    item?.source?.thumb ? item?.source?.thumb : item?.target?.thumb
                                                );
                                                return (
                                                    <HStack
                                                        borderBottomWidth={ 1 }
                                                        borderBottomColor={ 'gray.400' }
                                                        key={ item?.id + i }
                                                        height={ '125px' }

                                                    >
                                                        { followRequestData?.filter( x => x?.id == item?.id )?.length > 0 &&
                                                            <>
                                                                <Pressable
                                                                    height={ '100%' }
                                                                    padding={ '24px' }
                                                                    _hover={ { backgroundColor: 'gray.100' } }
                                                                    width={ '100%' }
                                                                    onPress={ () =>
                                                                    {
                                                                        navigate(
                                                                            `/profile/${ item?.id }/${ item?.region }`,
                                                                            {
                                                                                state: {
                                                                                    user: {
                                                                                        id: item?.id,
                                                                                        pk: item?.pk,
                                                                                        region: item?.region,
                                                                                        type: item?.type,
                                                                                        name: item?.title,
                                                                                    },
                                                                                },
                                                                            }
                                                                        )
                                                                        setShowFollowingModal( !showFollowingModal );
                                                                    } }

                                                                >
                                                                    <HStack>
                                                                        <ProfilePic
                                                                            width={ '56px' }
                                                                            height={ '56px' }
                                                                            link={ item }
                                                                        />
                                                                        <VStack left={ '24px' } width={ '70%' }>
                                                                            <Pressable
                                                                            >
                                                                                <Text
                                                                                    color={ 'black' }
                                                                                    fontWeight={ 500 }
                                                                                    numberOfLines={ 1 }
                                                                                    width={ '200px' }
                                                                                    fontSize={ '18px' }
                                                                                    text-overflow={ 'ellipsis' }
                                                                                >
                                                                                    { item?.title }
                                                                                </Text>
                                                                            </Pressable>
                                                                            <HStack left={ 0 } top={ '20px' } space={ 5 }>
                                                                                <Button
                                                                                    bgColor={ '#32AE78' }
                                                                                    width={ '120px' }
                                                                                    height={ '40px' }
                                                                                    paddingRight={ '20px' }
                                                                                    paddingLeft={ '20px' }
                                                                                    rounded='full'
                                                                                    isLoading={ ( item === Loader?.alert && Loader?.type === 'followback' ) ? true : false }
                                                                                    onPress={ () => handleAlert( item, 'followback' ) }

                                                                                >
                                                                                    <Text color={ '#ffffff' } fontWeight={ 500 }>FOLLOW BACK</Text>
                                                                                </Button>
                                                                                <Button
                                                                                    background={ '#EEEEEE' }
                                                                                    width={ '120px' }
                                                                                    paddingRight={ '20px' }
                                                                                    paddingLeft={ '20px' }
                                                                                    height={ '40px' }
                                                                                    rounded='full'
                                                                                    onPress={ () => HandleRemoveFollowRequestData( item ) }
                                                                                >
                                                                                    <Text color={ 'rgba(0, 0, 0, 0.50)' } fontWeight={ 500 }>CLOSE</Text>
                                                                                </Button>
                                                                            </HStack>
                                                                        </VStack>
                                                                    </HStack>
                                                                </Pressable>
                                                            </>
                                                        }
                                                        { followRequestData?.filter( x => x?.id == item?.id )?.length == 0 &&
                                                            ( <Pressable
                                                                height={ '100%' }
                                                                padding={ '24px' }
                                                                _hover={ { backgroundColor: 'gray.100' } }
                                                                width={ '100%' }
                                                                onPress={ () =>
                                                                {
                                                                    ( item?.source?.type == 'Club' && item?.source ) ||
                                                                        ( item?.source?.type == 'School' && item?.source )
                                                                        ? navigate(
                                                                            `/organizations/${ item?.source?.id }/${ item?.source?.region }/${ item?.source?.type }`,
                                                                            {
                                                                                state: {
                                                                                    isCreate: false,
                                                                                    orgType: item?.source?.type,
                                                                                    org: item?.source,
                                                                                },
                                                                            }
                                                                        )
                                                                        : item?.target?.type == 'Club' || item?.target?.type == 'School'
                                                                            ? navigate(
                                                                                `/organizations/${ item?.target?.id }/${ item?.target?.region }/${ item?.target?.type }`,
                                                                                {
                                                                                    state: {
                                                                                        isCreate: false,
                                                                                        orgType: item?.target?.type,
                                                                                        org: item?.target,
                                                                                    },
                                                                                }
                                                                            )
                                                                            : item?.source?.type == 'LeagueTeam' && item?.source
                                                                                ? navigate(
                                                                                    `/season/${ item?.source?.id }/${ item?.source?.region }`,
                                                                                    { state: { isCreate: false, team: item?.source } }
                                                                                )
                                                                                : item?.target?.type == 'LeagueTeam'
                                                                                    ? navigate(
                                                                                        `/season/${ item?.target?.id }/${ item?.target?.region }`,
                                                                                        { state: { isCreate: false, team: item?.target } }
                                                                                    )
                                                                                    : item?.source?.type == 'Team' && item?.source
                                                                                        ? navigate( `/teams/${ item?.source?.id }/${ item?.source?.region }`, {
                                                                                            state: { isCreate: false, team: item?.source },
                                                                                        } )
                                                                                        : item?.target?.type == 'Team'
                                                                                            ? navigate( `/teams/${ item?.target?.id }/${ item?.target?.region }`, {
                                                                                                state: { isCreate: false, team: item?.target },
                                                                                            } )
                                                                                            : item?.source?.type == 'Person'
                                                                                                ? navigate(
                                                                                                    `/profile/${ item?.source?.id }/${ item?.source?.region }`,
                                                                                                    {
                                                                                                        state: {
                                                                                                            user: {
                                                                                                                id: item?.source?.id,
                                                                                                                pk: item?.source?.pk,
                                                                                                                region: item?.source?.region,
                                                                                                                type: item?.source?.type,
                                                                                                                name: item?.source?.title,
                                                                                                            },
                                                                                                        },
                                                                                                    }
                                                                                                )
                                                                                                : item?.target?.type == 'profile'
                                                                                                    ? navigate(
                                                                                                        `/profile/${ item?.target?.id }/${ item?.target?.region }`,
                                                                                                        {
                                                                                                            state: {
                                                                                                                user: {
                                                                                                                    id: item?.target?.id,
                                                                                                                    pk: item?.target?.pk,
                                                                                                                    region: item?.target?.region,
                                                                                                                    type: item?.target?.type,
                                                                                                                    name: item?.target?.title,
                                                                                                                },
                                                                                                            },
                                                                                                        }
                                                                                                    )
                                                                                                    : navigate(
                                                                                                        `/profile/${ item?.target?.id }/${ item?.target?.region }`,
                                                                                                        {
                                                                                                            state: {
                                                                                                                user: {
                                                                                                                    id: item?.target?.id,
                                                                                                                    pk: item?.target?.pk,
                                                                                                                    region: item?.target?.region,
                                                                                                                    type: item?.target?.type,
                                                                                                                    name: item?.target?.title,
                                                                                                                },
                                                                                                            },
                                                                                                        }
                                                                                                    );
                                                                } }
                                                            >
                                                                <HStack>
                                                                    <ProfilePic
                                                                        width={ '56px' }
                                                                        height={ '56px' }
                                                                        link={ item?.source ? item?.source : item?.target }
                                                                    />
                                                                    <VStack left={ '24px' } width={ '70%' }>

                                                                        <Pressable
                                                                            onPress={ () => handlePress( item ) }
                                                                        >
                                                                            <Text
                                                                                color={ 'black' }
                                                                                fontWeight={ 500 }
                                                                                numberOfLines={ 1 }
                                                                                width={ '200px' }
                                                                                fontSize={ '18px' }
                                                                                text-overflow={ 'ellipsis' }
                                                                            >
                                                                                { item?.source?.title
                                                                                    ? item?.source?.title
                                                                                    : item?.target?.title }
                                                                            </Text>
                                                                        </Pressable>
                                                                        { ( ( item?.data?.type === 'Member' || item.type === 'Member' ) && item?.source?.type === 'Person' ) ? (
                                                                            <Text color={ 'rgba(0, 0, 0, 0.50)' } fontSize={ '16px' } fontWeight={ 400 }>
                                                                                { Loc?.currentLang?.alertType?.friendreq }
                                                                            </Text>
                                                                        ) : (
                                                                            <Text color={ 'rgba(0, 0, 0, 0.50)' } width={ '190px' } numberOfLines={ 1 } fontSize={ '16px' } fontWeight={ 400 }>
                                                                                { item?.data?.reason
                                                                                    ? item?.data?.reason : item?.alertType === 'NewRequestToStaff' && item?.data?.type == "Follow" ? 'Request to Follow your ' + item?.data?.target?.title :
                                                                                        item?.alertType === 'NewRequestToStaff' && item?.data?.type == "Joins" ? 'Request to Join your ' + item?.data?.target?.type
                                                                                            : item?.type
                                                                                                ? item?.type
                                                                                                : item?.data?.reason
                                                                                                    ? item?.data?.reason
                                                                                                    : item?.data?.type == "Join" ? "Join Member" : item?.data?.type }{ ' ' }
                                                                                { item?.alertType === 'NewRequestToStaff' ? '' : 'Request' }
                                                                            </Text>
                                                                        ) }
                                                                        <Text color={ 'rgba(0, 0, 0, 0.50)' } fontSize={ '16px' } fontWeight={ 400 }>
                                                                            { Moment( item?.date ).fromNow() }
                                                                        </Text>
                                                                    </VStack>
                                                                    <VStack space={ 1 } width={ '15%' }>
                                                                        <Button
                                                                            p={ '8px' }
                                                                            bgColor={
                                                                                ( item === Loader?.alert && Loader?.type === 'Accepted' || isAcceptAll )
                                                                                    ? 'AVColor.primary'
                                                                                    : '#eeeeee'
                                                                            }
                                                                            borderRadius={ 50 }
                                                                            height='max-content'
                                                                            width='min-content'
                                                                            margin-left='100%'
                                                                            isDisabled={ ( item === Loader?.alert && Loader?.type === 'Accepted' || isAcceptAll || isLoading )
                                                                                ? true
                                                                                : false || ( item === Loader?.alert && Loader?.type === 'Rejected' || isRejectAll || isLoading )
                                                                                    ? true
                                                                                    : false }
                                                                            isLoading={
                                                                                ( item === Loader?.alert && Loader?.type === 'Accepted' || isAcceptAll )
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onPress={ () => handleAlert( item, 'Accepted' ) }
                                                                        >
                                                                            <TickIcon size='25px' color='#32AE78' />
                                                                        </Button>
                                                                        <Button
                                                                            p={ '8px' }
                                                                            bgColor={
                                                                                ( item === Loader?.alert && Loader?.type === 'Rejected' || isRejectAll )
                                                                                    ? 'AVColor.primary'
                                                                                    : '#eeeeee'
                                                                            }
                                                                            borderRadius={ 50 }
                                                                            isDisabled={ ( item === Loader?.alert && Loader?.type === 'Accepted' || isAcceptAll || isLoading )
                                                                                ? true
                                                                                : false || ( item === Loader?.alert && Loader?.type === 'Rejected' || isRejectAll || isLoading )
                                                                                    ? true
                                                                                    : false }
                                                                            height='max-content'
                                                                            width='min-content'
                                                                            margin-left='100%'
                                                                            isLoading={
                                                                                ( item === Loader?.alert && Loader?.type === 'Rejected' || isRejectAll )
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onPress={ () => handleAlert( item, 'Rejected' ) }
                                                                        >
                                                                            <CrossIcon size='25px' color='#E02020' />
                                                                        </Button>
                                                                    </VStack>
                                                                </HStack>
                                                            </Pressable> ) }

                                                    </HStack>
                                                );
                                            } ) }
                                        </Box>
                                    </ScrollView>
                                ) }
                                { requestdata?.length > 0 && ( <HStack justifyContent={ 'center' } space={ '16px' } marginTop={ '20px' }>
                                    <Button
                                        backgroundColor={ '#32AE78' }
                                        rounded={ 'full' }
                                        height={ '40px' }
                                        width={ '45%' }
                                        isDisabled={ isDisable ? isDisable : disable }
                                        isLoading={ isAcceptAll }
                                        onPress={ () => handleAllRequest( 'Accepted' ) }
                                    >
                                        <HStack flexDirection={ 'row' } space={ 2 }>
                                            <TickIcon size='25px' color='white' />
                                            <Text color={ 'white' } fontSize={ '16px' }>
                                                { Loc.currentLang.menu.acceptAll }
                                            </Text>
                                        </HStack>
                                    </Button>
                                    <Button

                                        backgroundColor={ isRejectAll ? '#32AE78' : '#EEEEEE' }
                                        rounded={ 'full' }
                                        height={ '40px' } isLoading={ isRejectAll }
                                        width={ '45%' }
                                        isDisabled={ isDisable ? isDisable : disable }
                                        onPress={ () => handleAllRequest( 'Rejected' ) }
                                    >
                                        <HStack flexDirection={ 'row' } space={ 2 }>
                                            <CrossIcon size='25px' color='#FA6400' />
                                            <Text color={ 'rgba(0,0,0,0.5)' } fontSize={ '16px' }>
                                                { Loc.currentLang.menu.rejectAll }
                                            </Text>
                                        </HStack>
                                    </Button>
                                </HStack> ) }
                                { ismanagerequest && (
                                    <>
                                        <ScrollView showsHorizontalScrollIndicator={ false }>
                                            <Box h={ dimensions.height - 200 } overflowY={ 'auto' } top={ 3 }>
                                                { managerequestdata?.map( ( item : any, i ) => (
                                                    <Pressable
                                                        key={ item.id + i }
                                                        padding={ '10px' }
                                                        onPress={ () =>
                                                        {
                                                            navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                                state: {
                                                                    user: {
                                                                        id: item?.id,
                                                                        pk: item?.pk,
                                                                        region: item?.region,
                                                                        type: item?.type,
                                                                        name: item?.title,
                                                                    },
                                                                },
                                                            } );
                                                        } }
                                                    >
                                                        <Box padding='3px' key={ item.id + i }>
                                                            <HStack space={ 2 }>
                                                                <ProfilePic width={ 35 } height={ 35 } link={ item } />
                                                                <VStack>
                                                                    <Tooltip label={ item.title } openDelay={ 500 }>
                                                                        <Pressable
                                                                            onPress={ () =>
                                                                            {
                                                                                navigate( `/profile/${ item?.id }/${ item?.region }`, {
                                                                                    state: {
                                                                                        user: {
                                                                                            id: item?.id,
                                                                                            pk: item?.pk,
                                                                                            region: item?.region,
                                                                                            type: item?.type,
                                                                                            name: item?.title,
                                                                                        },
                                                                                    },
                                                                                } );
                                                                            } }
                                                                        >
                                                                            <Text fontWeight='bold' numberOfLines={ 1 } width={ '250px' }>
                                                                                { item.title }
                                                                            </Text>
                                                                        </Pressable>
                                                                    </Tooltip>
                                                                    <Text fontSize={ '12px' } numberOfLines={ 2 } width={ '250px' }>
                                                                        { item.blurb }
                                                                    </Text>
                                                                </VStack>

                                                                <Button
                                                                    p={ 1 }
                                                                    bgColor={ item === unfollowLoader ? 'AVColor.primary' : '#eeeeee' }
                                                                    borderRadius={ 50 }
                                                                    height='max-content'
                                                                    width='min-content'
                                                                    margin-left='100%'
                                                                    isLoading={ item === unfollowLoader ? true : false }
                                                                    onPress={ () => handleUnFollow( item ) }
                                                                >
                                                                    <CloseIcon size='15px' />
                                                                </Button>
                                                            </HStack>
                                                        </Box>
                                                    </Pressable>
                                                ) ) }
                                            </Box>
                                        </ScrollView>
                                    </>
                                ) }
                            </VStack>
                        </VStack>
                    </VStack>
                </Box>
            ) }
            {/* Notification Screen */ }
            { showAlertModal && (
                <Box
                    // position={['absolute']}
                    position='fixed'
                    top={ [ `${ 83 - 1 }px` ] }
                    right={ [ 0 ] }
                    w={ isMobile ? '100%' : '460px' }
                    h={ 'calc(100vh - 50px)' }
                    bgColor={ [ 'AVColor.white' ] }
                    shadow={ [ 10 ] }
                    borderColor={ 'AVColor.grey' }
                    borderWidth={ 1 }
                    zIndex={ [ 1000 ] }
                //borderRadius={8}
                >
                    <VStack padding={ '24px' } h={ '96%' } position={ 'relative' } >
                        <HStack justifyContent={ 'space-between' }>
                            <Text fontSize={ 24 } fontWeight={ 400 }>
                                { Loc.currentLang.menu.notifications }
                            </Text>
                            <HStack alignItems={ [ 'center' ] } space={ '26px' }>
                                <AVCancel callback={ handleCancelAlert } color={ '' } size={ undefined } />
                            </HStack>
                        </HStack>
                        <HStack mt={ [ 15 ] }>
                            <HStack
                                display={ 'flex' }
                                flex={ 1 }
                                justifyContent={ [ 'center' ] }
                                alignItems={ 'center' }
                                borderBottomColor={ 'AVColor.primary' }
                                borderBottomWidth={ isunread ? 4 : '0' }
                                pb={ '12px' }
                            >
                                <Pressable
                                    onPress={ () =>
                                    {
                                        setIsUnread( true );
                                        setIsAll( false );
                                    } }
                                >
                                    <Text fontSize={ 'md' } fontWeight={ 500 } color={ !isunread ? 'gray.500' : '' }>
                                        { Loc.currentLang.menu.unRead } ({ unreadAlertData?.length })
                                    </Text>
                                </Pressable>
                            </HStack>
                            <HStack
                                display={ 'flex' }
                                flex={ 1 }
                                justifyContent={ [ 'center' ] }
                                alignItems={ 'center' }
                                borderBottomColor={ 'AVColor.primary' }
                                borderBottomWidth={ isall ? 4 : '0' }
                            >
                                <Pressable
                                    onPress={ () =>
                                    {
                                        setIsUnread( false );
                                        setIsAll( true );
                                    } }
                                >
                                    <Text variant={ 'md' } fontWeight={ 500 } color={ !isall ? 'gray.500' : '' }>
                                        { Loc.currentLang.menu.all }
                                    </Text>
                                </Pressable>
                            </HStack>
                        </HStack>
                        <Divider />
                        <VStack h={ '100%' } flex={ 1 } marginTop={ '24px' }>
                            <ScrollView maxHeight={ dimensions.height - 200 }>
                                { isunread &&
                                    ( unreadAlertData?.length > 0 ? (
                                        <HStack flexWrap={ 'wrap' } justifyContent={ 'center' }>
                                            <FlatList
                                                removeClippedSubviews={ false }
                                                scrollEnabled={ true }
                                                disableVirtualization={ true }
                                                data={ unreadAlertData }
                                                renderItem={ RenderalertTab }
                                                initialNumToRender={ 5 }
                                                maxToRenderPerBatch={ 5 }
                                                windowSize={ 3 }
                                                onEndReachedThreshold={ 0.1 }
                                                keyExtractor={ ( item : any, index ) => item?.id + index }
                                            />
                                        </HStack>
                                    ) : (
                                        <Center height={ 300 }>{ Loc.currentLang.menu.noRecords }</Center>
                                    ) ) }
                                { isall &&
                                    ( allAlertData?.length > 0 ? (
                                        <HStack flexWrap={ 'wrap' } justifyContent={ 'center' }>
                                            <FlatList
                                                removeClippedSubviews={ false }
                                                disableVirtualization={ true }
                                                data={ allAlertData }
                                                renderItem={ RenderalertTab }
                                                initialNumToRender={ 5 }
                                                maxToRenderPerBatch={ 5 }
                                                windowSize={ 3 }
                                                scrollEnabled={ true }
                                                onEndReachedThreshold={ 0.1 }
                                                keyExtractor={ ( item : any, index ) => item?.id + index }
                                            />
                                        </HStack>
                                    ) : (
                                        <Center height={ 300 }>{ Loc.currentLang.menu.noRecords }</Center>
                                    ) ) }
                            </ScrollView>
                        </VStack>
                        { unreadAlertData?.length > 0 && isunread && (
                            <HStack height={ '50px' } width={ '100%' } justifyContent={ 'center' } alignItems={ 'center' } top={ '10px' }>
                                <HStack position={ 'fixed' }>
                                    <Button
                                        width={ '155px' }
                                        height={ '40px' }
                                        backgroundColor={ '#EEEEEE' }
                                        rounded={ 'full' }
                                        onPress={ () =>
                                        {
                                            handleDeleteAll();
                                            setUnreadAlertData( [] );
                                            setunread( [] );
                                        } }
                                    >
                                        <Text fontWeight={ 700 } fontSize={ '16px' } color={ 'gray.700' } textTransform={ 'uppercase' }>{ Loc.currentLang.menu.deleteAll }</Text>
                                    </Button>
                                </HStack>
                            </HStack>
                        ) }
                    </VStack>
                </Box>
            ) }
            {/* Message   Screen */ }
            { showConvoListModal && (
                <CreateNewMessage isHeader={ isHeader } convosearch={ convosearch } convoListData={ convoListData } userconvolistdata={ userconvolistdata } UnReadChatMessage={ UnReadChatMessage } handleGetConvo={ handleGetConvo } setShowEmoji={ setShowEmoji } setShowConvoFilePicker={ setShowConvoFilePicker } setValue={ setValue } setShowAlertModal={ setShowAlertModal } setShowFollowingModal={ setShowFollowingModal } setShowConvoListModal={ setShowConvoListModal } setMdlConvoVisible={ setMdlConvoVisible } setMdlCreateConvoVisible={ setMdlCreateConvoVisible } setConvoLoading={ setConvoLoading } handleCreateModel={ handleCreateModel } setconvosearch={ setconvosearch } setconvoListData={ setconvoListData } convoval={ convoval } />
            ) }
            { mdlCreateConvoVisible && (
                <Messages isHeader={ isHeader } setShowEmoji={ setShowEmoji } RenderImages={ RenderImages } showEmoji={ showEmoji } imageInputRef={ imageInputRef } onEmojiClick={ onEmojiClick } handleuserRedirect={ handleuserRedirect } getItemLayout={ getItemLayout } ReplyLoadMedia={ ReplyLoadMedia } setHeader={ setHeader } setShowAllImage={ setShowAllImage } setSingleImage={ setSingleImage } setShowPreview={ setShowPreview } setConvoImage={ setConvoImage } setItemHeights={ setItemHeights } itemHeights={ itemHeights } props={ props } mdlConvoVisible={ mdlConvoVisible } textSearchRef={ textSearchRef } mdlCreateConvoVisible={ mdlCreateConvoVisible } isTyping={ isTyping } typingUserName={ typingUserName }
                    setMdlCreateConvoVisible={ setMdlCreateConvoVisible } toFK={ toFK }
                    searchInput={ searchInput }
                    filteredResults={ filteredResults }
                    showSearch={ showSearch }
                    showFilePicker={ showFilePicker }
                    handleCreateModel={ handleCreateModel }
                    searchItems={ searchItems }
                    handleSentToProfile={ handleSentToProfile }
                    handleSendMessage={ handleSendMessage }
                    setConvoLoading={ setConvoLoading }
                    isLoading={ isLoading }
                    handleSubject={ handleSubject }
                    subject={ subject }
                    handleMessage={ handleMessage }
                    handleRemoveItem={ handleRemoveItem }
                    getImageGalleryFromFilePickerCreateConvo={ getImageGalleryFromFilePickerCreateConvo }
                    convoSubject={ convoSubject }
                    setMdlConvoVisible={ setMdlConvoVisible }
                    convoPost={ convoPost }
                    convo={ convo }
                    showConvoFilePicker={ showConvoFilePicker }
                    handleGetConvo={ handleGetConvo }
                    handleSendConvoMessage={ handleSendConvoMessage }
                    replyValue={ replyValue }
                    setreplyValue={ setreplyValue }
                    handleChatMessage={ handleChatMessage }
                    media={ media }
                    value={ value }
                    profile={ profile }
                    setValue={ setValue }
                    Loading={ Loading }
                    images={ images }
                    setImages={ setImages }
                    videoUrls={ videoUrls }
                    setvideos={ setvideos }
                    handleImages={ handleImages }
                    handleFilePicker={ handleFilePicker }
                    onRefresh={ onRefresh }
                    setSubject={ setSubject }
                    settoFK={ settoFK }
                    handlePublish={ handlePublish }
                    setIsHide={ setIsHide }
                    isHide={ isHide }
                    isImageProcessing={ isImageProcessing }
                    setMedia={ setMedia }
                    refConvoList={ refConvoList }
                    setShowConvoFilePicker={ setShowConvoFilePicker }
                    HandleEditSet={ HandleEditSet }
                    handleUpdatePost={ handleUpdatePost }
                    itemEdit={ itemEdit }
                    editingImage={ editingImage }
                    setSearchInput={ setSearchInput }
                    setShowSearch={ setShowSearch }
                    setEditingImage={ setEditingImage } setConvoPost={ setConvoPost } fetchloading={ fetchloading } />
            ) }
            { showPreview && (
                <AVImageSlider slideItems={ singleImage } setCommentValue={ setCommentValue } previewitem={ previewitem } setpreviewitem={ setpreviewitem } type={ "IMG" } settype={ undefined } fullscreen={ undefined } setIsFullScreen={ undefined } setShowDetails={ undefined } setIscommentItem={ undefined } setIsCommentSlider={ undefined } isCommentSlider={ undefined } handleslideChange={ undefined } isHeader={ isHeader } setHeader={ setHeader } setShowPreview={ setShowPreview } />
            ) }

            {/* showBurgerMenu */ }
            { showBurgerMenu && (
                <Hidden from={ 'lg' }>
                    <Box
                        position={ [ 'fixed' ] }
                        top={ [ `${ 84 - 1 }px` ] }
                        right={ [ 0 ] }
                        w={ '250px' }
                        h={ 'calc(100vh - 145px)' }
                        bgColor={ [ 'AVColor.white' ] }
                        borderColor={ 'AVColor.grey' }
                        borderWidth={ 1 }
                        zIndex={ [ 100 ] }
                    >
                        <ScrollView maxHeight={ 'calc(100vh - 155px)' }>
                            <Box>
                                <Pressable
                                    onPress={ () =>
                                    {
                                        navigate( '/my-events' );
                                        setShowBurgerMenu( !showBurgerMenu );
                                    } }
                                    _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                >
                                    <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                        <EventsIcon size={ 26 } color='AVColor.primary' />
                                        <Text fontSize={ '19px' }>{ Loc.currentLang.menu.events }</Text>
                                    </HStack>
                                </Pressable>
                                <Pressable
                                    onPress={ () =>
                                    {
                                        navigate( '/my-teams' );
                                        setShowBurgerMenu( !showBurgerMenu );
                                    } }
                                    _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                >
                                    <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                        <GroupIcon size={ 26 } color='AVColor.primary' />
                                        <Text fontSize={ '19px' }>Network</Text>
                                    </HStack>
                                </Pressable>
                            </Box>
                            <Divider />
                            <Box>
                                <Pressable onPress={ () => setIsCreateNew( !isCreateNew ) }>
                                    <HStack paddingY='6px' paddingX={ '12px' } justifyContent={ 'space-between' }>
                                        <Text fontWeight='medium' fontSize={ '19px' } color={ 'AVColor.primary' }>
                                            { Loc.currentLang.post.createNew }
                                        </Text>
                                        { isCreateNew ? (
                                            <ChevronDownIcon size={ '22px' } />
                                        ) : (
                                            <ChevronUpIcon size={ '22px' } />
                                        ) }
                                    </HStack>
                                </Pressable>
                                { isCreateNew && (
                                    <Box>
                                        {/*Team*/ }
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                navigate( `/teams/${ "Create" }/${ currentUser.region }`, { state: { isCreate: true, type: 'Team' } } )
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreateNew( !isCreateNew );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <CreateTeam width={ '27' } height={ '20' } color={ '#32AE78' } />
                                                <Text fontSize={ '19px' }>{ Loc.currentLang.menu.team }</Text>
                                            </HStack>
                                        </Pressable>
                                        {/*Club*/ }
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                navigate( `/organizations/${ "Create" }/${ currentUser.region }/${ "Club" }`, {
                                                    state: { isCreate: true, type: 'Club' },
                                                } )
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreateNew( !isCreateNew );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <CreateClub width={ '27' } height={ '20' } color={ '#32AE78' } />
                                                <Text fontSize={ '19px' }>{ Loc.currentLang.menu.club }</Text>
                                            </HStack>
                                        </Pressable>
                                        {/*EliteClub*/ }
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                navigate( `/organizations/${ "Create" }/${ currentUser.region }/${ "EliteClub" }`, {
                                                    state: { isCreate: true, type: 'EliteClub' },
                                                } )
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreateNew( !isCreateNew );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <CreateElite width={ '20' } height={ '20' } color={ '#32AE78' } />
                                                <Text fontSize={ '19px' }>{ Loc.currentLang.menu.eliteclub }</Text>
                                            </HStack>
                                        </Pressable>
                                        {/*School*/ }
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                navigate( `/organizations/${ "Create" }/${ currentUser.region }/${ "School" }`, {
                                                    state: { isCreate: true, type: 'School' },
                                                } )
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreateNew( !isCreateNew );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <CreateSchool width={ '20' } height={ '20' } color={ '#32AE78' } />
                                                <Text fontSize={ '19px' }>{ Loc.currentLang.menu.school }</Text>
                                            </HStack>
                                        </Pressable>
                                        {/*Group*/ }
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                navigate( `/organizations/${ "Create" }/${ currentUser.region }/${ "Group" }`, {
                                                    state: { isCreate: true, type: 'Group' },
                                                } )
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreateNew( !isCreateNew );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <CreateGroup width={ '28 ' } height={ '27' } color={ '#32AE78' } />
                                                <Text fontSize={ '19px' }>{ Loc.currentLang.menu.group }</Text>
                                            </HStack>
                                        </Pressable>
                                        {/*Class*/ }
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                navigate( `/organizations/${ "Create" }/${ currentUser.region }/${ "Class" }`, {
                                                    state: { isCreate: true, type: 'Class' },
                                                } )
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreateNew( !isCreateNew );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <CreateClass width={ '27' } height={ '27' } color={ '#32AE78' } />
                                                <Text fontSize={ '19px' }>{ Loc.currentLang.menu.class }</Text>
                                            </HStack>
                                        </Pressable>
                                        {/* Graduation Class*/ }
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                navigate( `/organizations/${ "Create" }/${ currentUser.region }/${ "School" }`, {
                                                    state: { isCreate: true, type: 'School' },
                                                } )
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreateNew( !isCreateNew );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <CreateGraduation width={ '28 ' } height={ '23' } color={ '#32AE78' } />
                                                <Text fontSize={ '19px' }>{ Loc.currentLang.menu.graduatingClass }</Text>
                                            </HStack>
                                        </Pressable>
                                        {/*Organization*/ }
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                navigate( `/organizations/${ "Create" }/${ currentUser.region }/${ "Organization" }`, {
                                                    state: { isCreate: true, type: 'Organization' },
                                                } )
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreateNew( !isCreateNew );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <CreateOrganization width={ '27' } height={ '27' } color={ '#32AE78' } />
                                                <Text fontSize={ '19px' }>{ Loc.currentLang.menu.organization }</Text>
                                            </HStack>
                                        </Pressable>
                                        {/*Neighborhood*/ }
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                navigate( `/organizations/${ "Create" }/${ currentUser.region }/${ "Neighborhood" }`, {
                                                    state: { isCreate: true, type: 'Neighborhood' },
                                                } )
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreateNew( !isCreateNew );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <CreateNeighborhood width={ '22 ' } height={ '25 ' } color={ '#32AE78' } />
                                                <Text fontSize={ '19px' }>{ Loc.currentLang.menu.neighborHood }</Text>
                                            </HStack>
                                        </Pressable>
                                        {/*Community*/ }
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                navigate( `/organizations/${ "Create" }/${ currentUser.region }/${ "Community" }`, {
                                                    state: { isCreate: true, type: 'Community' },
                                                } )
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreateNew( !isCreateNew );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <CreateCommunity width={ '26' } height={ '28' } color={ '#32AE78' } />
                                                <Text fontSize={ '19px' }>{ Loc.currentLang.menu.community }</Text>
                                            </HStack>
                                        </Pressable>


                                    </Box>
                                ) }
                            </Box>
                            <Divider />
                            { currentUser?.wards?.length > 0 && !loginLink && (
                                <VStack>
                                    <Pressable onPress={ () => setIsCreate( !isCreate ) }>
                                        <HStack paddingY='6px' paddingX={ '12px' } justifyContent={ 'space-between' }>
                                            <Text fontWeight='medium' fontSize={ '19px' } color={ 'AVColor.primary' }>
                                                { Loc.currentLang.menu.myDependants }
                                            </Text>
                                            { isCreate ? (
                                                <ChevronDownIcon size={ '22px' } />
                                            ) : (
                                                <ChevronUpIcon size={ '22px' } />
                                            ) }
                                        </HStack>
                                    </Pressable>
                                    <Box maxHeight={ '155px' } overflowY={ 'scroll' }>
                                        { isCreate &&
                                            currentUser?.wards?.length > 0 &&
                                            userisguardian &&
                                            !loginLink?.id &&
                                            currentUser?.wards?.map( ( item, index ) => (
                                                <Pressable
                                                    onPress={ () =>
                                                    {
                                                        handleuserchange( item );
                                                        setShowBurgerMenu( !showBurgerMenu );
                                                        setIsCreate( !isCreate );
                                                    } }
                                                    _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                                    key={ item.id }
                                                >
                                                    <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                        <ProfilePic width={ '35px' } height={ '35px' } link={ item } />
                                                        <Text fontSize={ '19px' } numberOfLines={ 1 }>
                                                            { item?.title }
                                                        </Text>
                                                    </HStack>
                                                </Pressable>
                                            ) ) }
                                    </Box>
                                </VStack>
                            ) }
                            <VStack>
                                <Box maxHeight={ '155px' } overflowY={ 'scroll' }>
                                    { loginLink && (
                                        <Pressable
                                            onPress={ () =>
                                            {
                                                handleuserchange( loginLink );
                                                setShowBurgerMenu( !showBurgerMenu );
                                                setIsCreate( !isCreate );
                                            } }
                                            _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                        >
                                            <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                                <ProfilePic
                                                    width={ '35px' }
                                                    height={ '35px' }
                                                    link={ Person.getLink( loginLink ) }
                                                />
                                                <Text fontSize={ '19px' } numberOfLines={ 1 }>
                                                    { Person.getLink( loginLink )?.title }
                                                </Text>
                                            </HStack>
                                        </Pressable>
                                    ) }
                                </Box>
                            </VStack>
                            { currentUser?.wards?.length > 0 && <Divider /> }
                            { loginLink && <Divider /> }
                            <Pressable
                                onPress={ () =>
                                {
                                    navigate( `/manageFollowings` );
                                    setShowProfile( false );
                                    setShowBurgerMenu( !showBurgerMenu );
                                } }
                                _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                            >
                                <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                    <FollowingIcon size={ 26 } color='AVColor.primary' />
                                    <Text fontSize={ '19px' }>{ Loc.currentLang.menu.followings }</Text>
                                </HStack>
                            </Pressable>
                            <Pressable
                                onPress={ OpenNotification }
                                _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                            >
                                <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                    <NotificationIcon size={ 26 } color='AVColor.primary' />
                                    <Text fontSize={ '19px' }>{ Loc.currentLang.menu.notifications }</Text>
                                </HStack>
                            </Pressable>
                            <Pressable
                                onPress={ () =>
                                {
                                    setShowConvoListModal( true );
                                    setShowAlertModal( false );
                                    setShowFollowingModal( false );
                                    setShowProfile( false );
                                    setShowBurgerMenu( !showBurgerMenu );
                                } }
                                _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                            >
                                <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                    <MessageIcon size={ 26 } color='AVColor.primary' />
                                    <Text fontSize={ '19px' }>{ Loc.currentLang.menu.messages }</Text>
                                </HStack>
                            </Pressable>
                            <Divider />
                            <Box>
                                <Pressable
                                    onPress={ () =>
                                    {
                                        navigate( `/accountsettings/${ currentUser?.id }/${ currentUser?.region }`, {
                                            state: {
                                                page: 'Home',
                                                Entity: currentUser,
                                            },
                                        } );
                                        setShowProfile( false );
                                        setShowBurgerMenu( !showBurgerMenu );
                                    } }
                                    _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                >
                                    <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                        <img src={ seting } height={ '25px' } width={ '25px' } />
                                        <Text fontSize={ '19px' }>{ Loc.currentLang.menu.settings }</Text>
                                    </HStack>
                                </Pressable>
                            </Box>
                            <Box>
                                <Pressable
                                    isDisabled={ currentUser?.status === 'Deleted' }
                                    onPress={ () =>
                                    {
                                        setshowAddinviteModal( true );
                                        setShowProfile( false );
                                        setpersonEmail( '' );
                                        setpersonPhone( '' );
                                        setpersonname( '' );
                                        setShowBurgerMenu( !showBurgerMenu );
                                    } }
                                    _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                >
                                    <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                        <img src={ adduser } height={ '25px' } width={ '25px' } />
                                        <Text fontSize={ '19px' }>{ Loc.currentLang.menu.inviteFriends }</Text>
                                    </HStack>
                                </Pressable>
                            </Box>

                            <Box>
                                <Pressable
                                    onPress={ () =>
                                    {
                                        window.open( 'https://actavivo.net/' );
                                        setShowProfile( false );
                                        setShowBurgerMenu( !showBurgerMenu );
                                    } }
                                    _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                >
                                    <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                        <img src={ about } height={ '25px' } width={ '25px' } />
                                        <Text fontSize={ '19px' }>{ Loc.currentLang.menu.about }</Text>
                                    </HStack>
                                </Pressable>
                            </Box>
                            <Box>
                                <Pressable
                                    onPress={ () =>
                                    {
                                        window.open( ' https://actavivo.net/how-to/' );
                                        setShowProfile( false );
                                        setShowBurgerMenu( !showBurgerMenu );
                                    } }
                                    _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                                >
                                    <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                        <img src={ help } height={ '25px' } width={ '25px' } />
                                        <Text fontSize={ '19px' }>{ Loc.currentLang.menu.help }</Text>
                                    </HStack>
                                </Pressable>
                            </Box>

                            {/*Feedback*/ }
                            <Pressable
                                onPress={ () =>
                                {
                                    navigate( '/teams/78b5b063-fd3e-4925-92bd-2a1888c6a46a/' + currentUser?.region, {
                                        state: {
                                            team: {
                                                id: '78b5b063-fd3e-4925-92bd-2a1888c6a46a',
                                                pk: 'a46a',
                                                region: currentUser?.region,
                                                type: 'Organization',
                                                name: 'Actavivo',
                                            },
                                            type: 'Feedback'
                                        },
                                    } );

                                    setShowBurgerMenu( !showBurgerMenu );
                                    setIsCreateNew( !isCreateNew );
                                } }
                                _hover={ { backgroundColor: 'AVColor.buttonBg' } }
                            >
                                <HStack space={ 3 } paddingY='8px' paddingLeft={ '20px' }>
                                    <HStack paddingY='1px' top='5px' >
                                        <ChatIcon size={ '22px' } color={ '#32AE78' } />
                                    </HStack>
                                    <Text fontSize={ '19px' }>{ Loc.currentLang.menu.feedBack }</Text>
                                </HStack>
                            </Pressable>
                            <Divider />

                            <Button
                                mx={ 5 }
                                mt={ 5 }
                                variant={ 'unstyled' }
                                backgroundColor={ '#eeeeee' }
                                _text={ { color: 'AVColor.secondary' } }
                                onPress={ () => { handleLogout() } }
                            >
                                { Loc.currentLang.menu.logout }
                            </Button>
                        </ScrollView>
                    </Box>
                </Hidden>
            ) }
        </>
    );
}

const ReplyLoadMedia = ( { item, isDetail = false } ) =>
{
    const [ imageUrls, setImagesUrl ] = React.useState<any>( [] );
    const [ videoUrls, setVideoUrl ] = React.useState<any>( [] );
    const [ Media, setMedia ] = React.useState<any>( [] );
    let videomedia : any = [];
    let imagemedia : any = [];

    React.useEffect( () =>
    {
        setMedia( [] )
        if (
            ( item?.galleryPost &&
                item?.galleryPost?.length > 0 &&
                item?.media?.type?.startsWith( 'image' ) ) ||
            ( item?.media?.storeId && item?.media?.type?.startsWith( 'image' ) )
        )
        {
            for ( let i = 0; i < item?.galleryPost?.length; i++ )
            {
                imagemedia.push( item?.galleryPost[ i ].media );
            }
            if ( item?.media?.storeId && item?.media?.type?.startsWith( 'image' ) )
            {
                imagemedia.push( item?.media );
                //let images = imagemedia?.length && imagemedia.map((i) => AV.Media.getURL(i));
                let images = imagemedia;
                setImagesUrl( images );
                setMedia( images )
            }
        } else
        {
            for ( var j = 0; j < item?.galleryPost?.length; j++ )
            {
                if (
                    item?.galleryPost[ j ]?.media?.storeId &&
                    item?.galleryPost[ j ]?.media?.type?.startsWith( 'video' )
                )
                {
                    videomedia.push( item?.galleryPost[ j ]?.media );
                    setVideoUrl( videomedia );
                } else
                {
                    imagemedia.push( item?.galleryPost[ j ]?.media );
                    //let images = imagemedia?.length && imagemedia.map((i) => AV.Media.getURL(i));
                    let images = imagemedia;
                    setImagesUrl( images );
                    setMedia( images )
                }
            }
            if ( item?.media?.storeId && item?.media?.type?.startsWith( 'video' ) )
            {
                videomedia.push( item?.media );
                setVideoUrl( videomedia );
                setMedia( videomedia )
            }
        }
    }, [] );

    return (
        <>
            <VStack px={ 1 }>
                <HStack overflow='scroll' maxWidth={ '400px' }>
                    { Media.map( ( item, i ) =>
                    {
                        let isImage = item?.type?.startsWith( 'image' ) ? true : false;
                        return (
                            <Box m={ 1 } width={ '100px' } height={ '72px' }>
                                <Box
                                    position={ 'absolute' }
                                    top={ 1 }
                                    right={ 1 }
                                    bgColor={ 'white' }
                                    rounded={ 'full' }
                                    zIndex={ 100 }
                                >

                                </Box>
                                { isImage ? (
                                    <img
                                        src={ AV.Media.getURL( item ) }
                                        alt=''
                                        className='Image'
                                        width='100%'
                                        height='100%'
                                        style={ { objectFit: 'cover' } }
                                    />
                                ) : (
                                    <video
                                        controls
                                        src={ AV.Media.getVideoPicURL( item ) }
                                        key={ i }
                                        width={ '100%' }
                                        height={ '100%' }
                                    />
                                ) }
                            </Box>
                        );
                    } ) }
                </HStack>
            </VStack>
        </>
    );
};

const RenderImages = ( {
    images,
    imageInputRef,
    setImages,
    media,
    setMedia,
    isImageProcessing,
    editingImage,
    setEditingImage
} ) =>
{
    useEffect( () =>
    {

    }, [ editingImage ] );

    let imgmedia : any = [];
    let vidmedia : any = [];

    images?.forEach( ( img ) =>
    {
        if ( editingImage )
        {
            if ( images?.length > 0 )
            {
                if ( img?.type?.startsWith( 'image' ) || img.startsWith( 'data:image' ) )
                {
                    imgmedia?.push( img );
                }
                else
                {
                    vidmedia.push( img );
                }
            }
        }
        else
        {
            if ( images?.length > 0 )
            {
                if ( img?.type?.startsWith( 'image' ) || img.startsWith( 'data:image' ) )
                {
                    imgmedia?.push( img );
                }
            }
            else vidmedia.push( img );
        }
    } );
    const deleteImage = ( index ) =>
    {
        const filterImages = images.filter( ( item, i ) => i !== index );
        setImages( filterImages );
        const filterMedia = media.filter( ( item, i ) => i !== index );
        setMedia( filterMedia );
        setEditingImage( false );
    };

    return (
        <VStack px={ 1 }>
            <HStack space={ 1 } alignItems={ 'center' }>
                <AVAdd addEvent={ () => imageInputRef.current.click() } color='#000' />
                <Text>Add more</Text>
            </HStack>
            <HStack overflow='scroll' maxWidth={ '400px' }>
                { images.map( ( item, i ) =>
                {
                    let isImage = item?.type?.startsWith( 'image' ) || item?.startsWith( 'data:image' ) || false;
                    return (
                        <Box m={ 1 } width={ '120px' } height={ '120px' }>
                            <Box
                                position={ 'absolute' }
                                top={ 1 }
                                right={ 1 }
                                bgColor={ 'white' }
                                rounded={ 'full' }
                                zIndex={ 100 }
                            >
                                { !isImageProcessing && (
                                    <AVCancel size={ 10 } callback={ () => deleteImage( i ) } color={ '#000000' } />
                                ) }
                            </Box>
                            { isImage ? (
                                <img
                                    src={ item?.type?.startsWith( 'image' ) ? Media.getURL( item ) : item }
                                    alt=''
                                    className='Image'
                                    width='100%'
                                    height='100%'
                                    style={ { objectFit: 'cover' } }
                                />
                            ) : (
                                <video
                                    controls
                                    src={ item }
                                    key={ i }
                                    width={ '100%' }
                                    height={ '100%' }
                                />
                            ) }
                        </Box>
                    );
                } ) }
            </HStack>
        </VStack>
    );
};

const UserMenu = ( {
    unreadAlertData,
    setShowAlertModal,
    setShowConvoListModal,
    setShowFollowingModal,
    setShowProfile,
    showProfile,
    convoPost,
    isMobile,
    requestdata,
    followRequestData,
    currentUser,
    followreqlist,
    setShowBurgerMenu,
    showBurgerMenu,
    showAddinviteModal,
    setshowAddinviteModal,
    personname,
    setpersonname,
    personEmail,
    setpersonEmail,
    personPhone,
    customUniq,
    setpersonPhone,
    setMdlCreateConvoVisible,
    setMdlConvoVisible,
    setConvoLoading,
    handleMessageIconClick

} ) =>
{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stateUser = useSelector( ( state : RootStateOrAny ) => state );
    const userisguardian = stateUser?.user?.userisguardian;
    const [ ispageLoading, setispageLoading ] = React.useState( false );
    const loginLink = stateUser?.user?.loginLink;
    const convoval = useSelector( ( state : RootStateOrAny ) => state.convo.convo[ 0 ] );
    const pathName = window.location.pathname;
    const [ isLoading, setisLoading ] = React.useState<any>( false );
    const [ data, setData ] = useState( [] );
    const [ responseData, setResponseData ] = useState( [] );
    const [ showpop, setisshowpop ] = useState( false );
    const useralertList = useSelector( ( state : RootStateOrAny ) => state?.alerts.alert[ 0 ]?.length > 0 ? state?.alerts.alert[ 0 ] : [] );
    const [ lastfeed, setlastfeed ] = useState<any>( [] );
    const lastUpdateFeed = useSelector( ( state : RootStateOrAny ) => state.user.lastUpdateFeed );
    const menuRef = useRef( null );
    useOutsideAlerter( menuRef );
    //Hook that alerts clicks outside of the passed ref
    function useOutsideAlerter ( ref )
    {
        React.useEffect( () =>
        {
            // Alert if clicked on outside of element
            function handleClickOutside ( event )
            {
                if ( ref.current && !ref.current.contains( event.target ) )
                {
                    setShowProfile( false );
                }
            }
            // Bind the event listener
            document.addEventListener( 'mousedown', handleClickOutside );
            return () =>
            {
                // Unbind the event listener on clean up
                document.removeEventListener( 'mousedown', handleClickOutside );
            };
        }, [ ref ] );
    }

    const handleuserchange = async ( item ) =>
    {
        navigate( '/' )
        setispageLoading( true );
        dispatch( setLoginLink( currentUser ) );
        dispatch( setFeedsLoad( true ) );
        if ( !loginLink?.id )
        {
            let val = await Server.impersonate( item, currentUser );
            let val1 = await Server.GetLoginLink();
            dispatch( setLoginLink( val1 ) );
            dispatch( setuserisguardian( false ) );
        } else
        {
            await Server.impersonate();
            dispatch( setLoginLink( [] ) );
            dispatch( setuserisguardian( true ) );
        }
        setShowProfile( false );
        await AV.Person.getSelf( true ).then( ( x ) => dispatch( setUser( x ) ) );
        dispatch( setFeeds( [] ) );
        dispatch( setAlerts( [] ) );
        dispatch( setConvo( [] ) );
        await getConvo();
        await gtalerts();
        navigate( `/profile/${ item?.id }/${ item?.region }`, {
            state: {
                user: {
                    id: item?.id,
                    pk: item?.pk,
                    region: item?.region,
                    type: item?.type,
                    name: item?.title,
                },
            },
        } )
        setTimeout( () =>
        {
            dispatch( setFeedsLoad( false ) );
        }, 20000 );
        setispageLoading( false );
    };

    const gtalerts = async () =>
    {
        let lastAlertDate = Alert._startDate == undefined ? new Date( 0 ) : Alert._startDate;
        let alertList = await Alert.List( lastAlertDate );
        dispatch( setAlerts( alertList?.alerts ) );
    };
    const getConvo = async () =>
    {
        let convovalue : AV.IConversation[] = [];
        let r = 0;
        let person : AV.IPerson = await AV.Person.getSelf();
        let ConvovalList = convoval;
        if ( person.convos !== undefined )
        {
            for ( let i = 0; i < person.convos.length; i++ )
            {
                let param : AV.IFeedParam = {
                    feed: person?.convos[ i ], checkArchive: false,
                    startDate: new Date( 0 ),
                    forward: true
                };

                // Create a function to handle the delay
                const delay = ( ms ) => new Promise( resolve => setTimeout( resolve, ms ) );

                // eslint-disable-next-line no-loop-func
                let data : any = {
                    Ifeed: param,
                    lasttime: new Date( 0 )
                };
                lastfeed?.push( data )
                dispatch( setLastFeedUpdate( lastfeed ) );
                setlastfeed( lastUpdateFeed )
                await AV.Feed.getFeed( param ).then( ( response : any ) =>
                {

                    let PostList = AV.Feed.getPostList( response );
                    if ( PostList?.length > 0 )
                    {
                        AV.Feed.getPosts( AV.AVEntity.getFk( response ), PostList?.slice( 0, 10 ) ).then( ( x : any ) =>
                        {
                            if ( x?.length > 0 )
                            {
                                x.sort( ( a : any, b : any ) =>
                                {
                                    const createDateA = new Date( a.createDate ) as any;
                                    const createDateB = new Date( b.createDate ) as any;
                                    return createDateB - createDateA;
                                } );
                                response.posts = x;
                                if ( response?.posts !== undefined && response?.posts?.length > 0 ) convovalue.push( response );
                                if ( person?.convos !== undefined && r === person?.convos?.length )
                                {
                                    Convoupdate( convovalue, ConvovalList );
                                }
                            }
                        } );
                    }
                    r++;
                } );

                // Add 10 seconds delay
                await delay( 100 );
            }
        }
    };

    const Convoupdate = ( convovalue, ConvovalList ) =>
    {
        if ( ConvovalList?.length === 0 || ConvovalList === undefined || ConvovalList === null ) ConvovalList = [];
        for ( let i = 0; i < convovalue?.length; i++ )
        {
            let excitingconvo = ConvovalList?.filter( ( x ) => x?.id == convovalue[ i ]?.id );
            if ( excitingconvo?.length > 0 )
            {
                for ( let j = 0; j < convovalue[ i ]?.posts?.length; j++ )
                {
                    let excitingconvopost = excitingconvo[ 0 ]?.posts?.filter(
                        ( x ) => x?.id === convovalue[ i ]?.posts[ j ]?.id
                    );
                    if ( excitingconvopost?.length === 0 )
                    {
                        excitingconvo[ 0 ]?.posts.push( convovalue[ i ]?.posts[ j ] );
                    }
                }
            } else ConvovalList.unshift( convovalue[ i ] );
        }
        ConvovalList = _.uniqBy( ConvovalList, 'id' );
        dispatch( setConvo( ConvovalList ) );
    };

    let userTitle : any = Person.getLink( currentUser )?.title;
    let titleLength =
        userTitle?.trim()?.length <= 10
            ? '16px'
            : userTitle?.trim()?.length <= 13
                ? '13px'
                : userTitle?.trim()?.length <= 16
                    ? '11px'
                    : userTitle?.trim()?.length <= 18
                        ? '10px'
                        : userTitle?.trim()?.length <= 20
                            ? '9px'
                            : userTitle?.trim()?.length >= 20
                                ? '9px'
                                : '16px';
    let maxlength = 20;
    let TitleData = userTitle?.substring( 0, maxlength );

    const Invitefriends = async ( item ) =>
    {
        let contact : AV.ITokenProcessRequest = {
            type: 'Invite',
            entity: AV.AVEntity.getLink( currentUser ),
        };
        let val : any = await AV.AVEntity.createTokenRequest( contact );
        let Window : any = window;
        Window.FB.init( {
            appId: '155052346752370', //Facebook AppID
            version: 'v12.0',
            cookie: true,
            status: true,
            xfbml: true,
        } );

        Window.FB.ui( {
            method: 'feed',
            name: 'Invite Friends',
            link: `https://actavivo.com/${ val.type }/${ val.token }`,
            description: 'Welcome to Actavivo',
            display: 'popup',
            to: item.id,
        } );
    };
    const searchfollowing = async ( val ) =>
    {
        setData(
            responseData?.filter( ( x : any ) =>
                val?.trim()?.length > 0
                    ? x?.name !== undefined && x?.name?.toLocaleLowerCase().includes( val.toLocaleLowerCase() )
                    : responseData
            )
        );
    };

    const deviceFCMToken = useSelector( ( state : RootStateOrAny ) => state.user.devicefcmtoken );

    const clearCache = useClearCache();
    const handleLogout = async () =>
    {
        try
        {
            if ( deviceFCMToken?.length > 0 )
            {
                await Person.removeFCMToken( deviceFCMToken );
            }

            // Clear the cache without reloading the window
            await clearCache( false );

            console.log( "Log out clearance is done!" );
            AVAuth.logout();
        } catch ( error )
        {
            console.error( "Error during logout process:", error );
            // Handle the error appropriately, e.g., show a notification to the user
        }
    };

    const convo = useralertList?.filter( ( x : any ) => x?.actionTaken == undefined && ( x?.alertType == "NewDM" || x?.alertType == "ReplyDM" ) && x?.data?.person?.id !== currentUser?.id );
    const unreadconvo = _.uniqBy( convo, 'data.feed.id' );
    return (
        <>
            <HStack alignItems={ 'center' } space={ [ 2 ] } borderWidth={ 0 }>
                <HStack
                    alignItems={ 'center' }
                    justifyContent={ 'space-between' }
                    bgColor={ '#EEEEEE' }
                    borderRadius={ 'full' }
                    p={ 1.5 }
                    backgroundColor={ 'AVColor.primary' }
                >
                    <Pressable

                        onPress={ () =>
                            navigate( `/profile/${ currentUser?.id }/${ currentUser?.region }`, {
                                state: { user: currentUser },
                            } )
                        }
                    >
                        <HStack space={ [ 0, 0, 2 ] }>
                            <ProfilePic width={ [ 26, 30 ] } height={ [ 26, 30 ] } link={ Person.getLink( currentUser ) } />
                            <Hidden till={ 'md' }>
                                <Text
                                    fontWeight='medium'
                                    alignSelf='center'
                                    fontFamily={ 'Roboto' }
                                    numberOfLines={ 1 }
                                    width={ '100px' }
                                    fontSize={ titleLength }
                                >
                                    { TitleData }
                                    { userTitle?.length > 20 ? '...' : '' }
                                </Text>
                            </Hidden>
                        </HStack>
                    </Pressable>
                    <Hidden till={ 'md' }>
                        <Pressable

                            onPress={ () =>
                            {
                                setShowProfile( ( prev ) => !prev ); setShowConvoListModal( false ); setMdlCreateConvoVisible( false ); setShowAlertModal( false );
                                setShowFollowingModal( false );
                                setShowConvoListModal( false );
                                setMdlConvoVisible( false );
                                setMdlCreateConvoVisible( false );
                                setConvoLoading( false );
                                /*setShowDetails( false )*/
                            } }
                        >
                            <HStack>
                                <Image source={ Dropdown } alt='Actavivo' size='25px' />
                            </HStack>
                        </Pressable>
                    </Hidden>
                </HStack>
                { ( currentUser?.requests?.length > 0 || requestdata.length > 0 || followRequestData?.length > 0 ) && (
                    <Pressable
                        onPress={ () =>
                        {
                            if ( currentUser?.status !== 'Deleted' )
                            {
                                setShowFollowingModal( true );
                                setShowAlertModal( false );
                                setShowConvoListModal( false );
                                setShowProfile( false );
                                setShowConvoListModal( false );
                                setShowProfile( false );
                                setShowBurgerMenu( false );
                                setShowConvoListModal( false );
                                setMdlConvoVisible( false );
                                setMdlCreateConvoVisible( false );
                                setConvoLoading( false );
                            }
                        } }
                        bgColor={ '#FFA500' }
                        _hover={ { bgColor: 'AVColor.secondary' } }
                        rounded={ 'full' }
                    >
                        <Box p='8px'>
                            { ( requestdata.length > 0 || currentUser?.requests?.length > 0 || followRequestData?.length > 0 ) && (
                                <Badge
                                    colorScheme='danger'
                                    rounded='full'
                                    variant='solid'
                                    alignSelf='flex-end'
                                    _text={ {
                                        fontSize: 12,
                                    } }
                                    top={ -10 }
                                    right={ -3 }
                                    position='absolute'
                                >
                                    { requestdata?.length }
                                </Badge>
                            ) }
                            <FollowingIcon color={ '#000000' } size={ [ '24px' ] } />
                        </Box>
                    </Pressable>
                ) }
                <Pressable
                    onPress={ () =>
                    {
                        if ( currentUser?.status !== 'Deleted' )
                        {
                            setMdlCreateConvoVisible( false )
                            setShowFollowingModal( false );
                            setShowConvoListModal( false );
                            setShowProfile( false );
                            setShowAlertModal( true );
                        }
                    } }
                    bgColor={ '#F5E732' }
                    _hover={ { bg: '#E0D42D' } }
                    rounded={ [ 'full' ] }
                >
                    <Box p='8px'>
                        { unreadAlertData?.length > 0 && (
                            <Badge
                                colorScheme='danger'
                                rounded='full'
                                variant='solid'
                                alignSelf='flex-end'
                                _text={ {
                                    fontSize: 12,
                                } }
                                top={ -10 }
                                right={ -3 }
                                position='absolute'
                            >
                                { unreadAlertData?.length }
                            </Badge>
                        ) }
                        <NotificationIcon size={ '24px' } color={ '#000000' } />
                    </Box>
                </Pressable>
                <Pressable
                    onPress={ () =>
                    {
                        if ( currentUser?.status !== 'Deleted' )
                        {
                            handleMessageIconClick()
                        }
                    } }
                    bgColor={ '#F5E732' }
                    _hover={ {
                        bg: '#E0D42D',
                    } }
                    rounded={ [ 'full' ] }
                >
                    <Box p='8px'>
                        { unreadconvo?.length > 0 && (
                            <Badge
                                colorScheme='danger'
                                rounded='full'
                                variant='solid'
                                alignSelf='flex-end'
                                _text={ {
                                    fontSize: 12,
                                } }
                                top={ -10 }
                                right={ -3 }
                                position='absolute'
                            >
                                { unreadconvo?.length }
                            </Badge>
                        ) }
                        <MessageIcon size={ '24px' } color={ '#000000' } />
                    </Box>
                </Pressable>
                {/* hamburger menu */ }
                <Hidden from={ 'lg' }>
                    <Pressable
                        backgroundColor={ 'AVColor.buttonBg' }
                        rounded={ 'full' }
                        _hover={ { backgroundColor: 'AVColor.grey' } }
                        onPress={ () =>
                        {
                            setShowBurgerMenu( ( prev ) => !prev );
                            setMdlCreateConvoVisible( false );
                            setShowFollowingModal( false );
                            setShowConvoListModal( false );
                            setShowProfile( false );
                            setShowAlertModal( false );

                        } }
                    >
                        <Box p={ [ 2, 3 ] }>
                            { !showBurgerMenu && <HamburgerIcon size={ '20px' } color={ 'AVColor.black' } /> }
                            { showBurgerMenu && <CloseIcon size={ '20px' } color={ 'AVColor.black' } /> }
                        </Box>
                    </Pressable>
                </Hidden>
            </HStack>
            { showAddinviteModal && (
                <InviteFriends
                    setshowAddinviteModal={ setshowAddinviteModal }
                    showAddinviteModal={ showAddinviteModal }
                />
            ) }

            { showpop && (
                <Box position='fixed' bottom={ 0 } right={ 0 } top={ 0 } left={ 0 }>
                    <div
                        style={ {
                            position: 'fixed',
                            top: '53%',
                            left: '50%',
                            background: '#ffffff',
                            borderRadius: '8px',
                            height: isMobile ? '55%' : '425px',
                            width: isMobile ? '82%' : '55%',
                            transform: 'translate(-50%, -50%)',
                        } }
                    >
                        <HStack
                            isDisabled={ currentUser?.status === 'Deleted' }
                            marginTop={ '10px' }
                            alignItems={ 'center' }
                            justifyContent={ 'space-between' }
                            borderBottomColor={ 'AVColor.grey' }
                            px={ 5 }
                            zIndex={ 99 }
                        >
                            <Text fontSize={ 24 }>{ Loc.currentLang.menu.friends }</Text>
                            <Input
                                width={ '260px' }
                                borderWidth={ 0 }
                                placeholder={ 'Search' }
                                variant='AVSearchInput'
                                placeholderTextColor='AVColor.textLite'
                                backgroundColor={ '#F5F5F5' }
                                borderRadius={ '22px' }
                                fontSize={ '16px' }
                                InputLeftElement={
                                    <SearchIcon ml='2' size='24px' color='#000' left={ '10px' } p={ '7px' } />
                                }
                                onChangeText={ ( e ) => searchfollowing( e ) }
                            />
                            <HStack alignItems={ 'center' } justifyContent={ 'center' } space={ 2 }>
                                <AVCancel callback={ () => setisshowpop( false ) } color={ '' } size={ undefined } />
                            </HStack>
                        </HStack>

                        <Box
                            backgroundColor={ 'AVColor.primary' }
                            marginTop={ '30px' }
                            marginLeft={ '115px' }
                            width={ '70%' }
                            paddingLeft={ '22px' }
                            borderRadius={ '5px' }
                        >
                            <HStack>
                                <HStack paddingX={ '4px' } width={ '85%' }>
                                    <Text
                                        fontWeight={ 'medium' }
                                        fontSize={ '19px' }
                                        text-overflow={ 'ellipsis' }
                                        textTransform={ 'capitalize' }
                                        textAlign={ 'center' }
                                        numberOfLines={ 1 }
                                        color={ 'white' }
                                    >
                                        { Loc.currentLang.profile.name }
                                    </Text>
                                </HStack>
                                <HStack paddingX={ '4px' } width={ '15%' } justifyContent={ 'center' }>
                                    <Text
                                        fontWeight={ 'medium' }
                                        fontSize={ '19px' }
                                        text-overflow={ 'ellipsis' }
                                        textAlign={ 'center' }
                                        numberOfLines={ 1 }
                                    ></Text>
                                </HStack>
                            </HStack>
                        </Box>

                        <VStack height={ '300px' } marginTop={ '10px' } overflowY={ 'auto' } overflowX={ 'hidden' }>
                            { data?.map( ( item : any ) => (
                                <Box
                                    backgroundColor={ '#EEEEEE' }
                                    marginTop={ '15px' }
                                    marginLeft={ '115px' }
                                    width={ '70%' }
                                    paddingLeft={ '22px' }
                                    borderRadius={ '5px' }
                                >
                                    <HStack>
                                        <HStack paddingX={ '4px' } width={ '85%' }>
                                            <Text
                                                fontWeight={ 'medium' }
                                                fontSize={ '19px' }
                                                text-overflow={ 'ellipsis' }
                                                numberOfLines={ 1 }
                                            >
                                                { item.name }
                                            </Text>
                                        </HStack>
                                        <HStack paddingX={ '4px' } width={ '15%' } justifyContent={ 'center' }>
                                            <Pressable
                                                isDisabled={ currentUser?.status === 'Deleted' }
                                                onPress={ () =>
                                                {
                                                    Invitefriends( item );
                                                } }
                                            >
                                                <Text
                                                    fontWeight={ 'medium' }
                                                    fontSize={ '19px' }
                                                    text-overflow={ 'ellipsis' }
                                                    numberOfLines={ 1 }
                                                    color={ '#00A7BC' }
                                                >
                                                    { Loc.currentLang.menu.invite }
                                                </Text>
                                            </Pressable>
                                        </HStack>
                                    </HStack>
                                </Box>
                            ) ) }
                        </VStack>
                    </div>
                </Box>
            ) }

            { showProfile && (
                <Box
                    shadow='9'
                    ref={ menuRef }
                    position={ 'absolute' }
                    bgColor='#FFFFFF'
                    rounded='lg'
                    minHeight={ '200px' }
                    width={ '200px' }
                    top={ '50px' }
                    right={ currentUser?.requests?.length > 0 ? '130px' : '90px' }
                    zIndex={ '1' }
                >
                    <VStack padding={ '20px' }>
                        <Pressable
                            onPress={ () =>
                            {
                                navigate( `/profile/${ currentUser?.id }/${ currentUser?.region }`, {
                                    state: { user: currentUser },
                                } );
                                setShowProfile( false );
                            } }
                        >
                            <HStack marginBottom={ '10px' }>
                                <Box rounded='full'>
                                    <ProfilePic width={ '48px' } height={ '48px' } link={ Person.getLink( currentUser ) } />
                                </Box>
                                <VStack marginLeft={ '10px' }>
                                    <Text
                                        fontWeight='medium'
                                        fontSize={ '20px' }
                                        fontFamily={ 'Roboto' }
                                        numberOfLines={ 1 }
                                        width={ '110px' }
                                        lineHeight={ '24px' }
                                    >
                                        { Person.getLink( currentUser )?.title }
                                    </Text>

                                    <Text
                                        fontSize={ '14px' }
                                        fontFamily={ 'Roboto' }
                                        marginLeft={ '1px' }
                                        color={ '#00A7BC' }
                                        lineHeight={ '24px' }
                                    >
                                        { Loc.currentLang.menu.viewMyPage }
                                    </Text>
                                </VStack>
                            </HStack>
                        </Pressable>
                        <Divider />

                        <Pressable
                            isDisabled={ currentUser?.status === 'Deleted' }
                            onPress={ () =>
                            {
                                navigate( `/manageFollowings` );
                                setShowProfile( false );
                            } }
                        >
                            <Text
                                marginTop={ '20px' }
                                marginLeft={ '10px' }
                                color={ '#00A7BC' }
                                fontSize={ '16px' }
                                lineHeight={ '10px' }
                            >
                                { Loc.currentLang.menu.followings }
                            </Text>
                        </Pressable>
                        {/*<Pressable*/ }
                        {/*  isDisabled={ currentUser?.status === 'Deleted' }*/ }
                        {/*  onPress={ () => {*/ }
                        {/*    navigate(`/signal`);*/ }
                        {/*    setShowProfile(false);*/ }
                        {/*  } }*/ }
                        {/*>*/ }
                        {/*  <Text*/ }
                        {/*    marginTop={ '20px' }*/ }
                        {/*    marginLeft={ '10px' }*/ }
                        {/*    color={ '#00A7BC' }*/ }
                        {/*    fontSize={ '16px' }*/ }
                        {/*    lineHeight={ '10px' }*/ }
                        {/*  >*/ }
                        {/*    signal R*/ }
                        {/*  </Text>*/ }
                        {/*</Pressable>*/ }
                        <Pressable
                            isDisabled={ currentUser?.status === 'Deleted' }
                            onPress={ () =>
                            {
                                navigate( `/accountsettings/${ currentUser?.id }/${ currentUser?.region }`, {
                                    state: {
                                        page: pathName,
                                        accountsetting: 'HomePage',
                                        Entity: currentUser,
                                    },
                                } );
                                setShowProfile( false );
                            } }
                        >
                            <Text
                                marginTop={ '20px' }
                                marginLeft={ '10px' }
                                fontSize={ '16px' }
                                color={ '#00A7BC' }
                                lineHeight={ '10px' }
                            >
                                { Loc.currentLang.menu.settings }
                            </Text>
                        </Pressable>
                        <Divider marginTop={ '15px' }></Divider>
                        <Pressable
                            onPress={ () =>
                            {
                                window.open( 'https://actavivo.net/' );
                            } }
                        >
                            <Text
                                marginTop={ '20px' }
                                marginLeft={ '10px' }
                                fontSize={ '16px' }
                                color={ '#00A7BC' }
                                lineHeight={ '10px' }
                            >
                                { Loc.currentLang.menu.about }
                            </Text>
                        </Pressable>
                        <Pressable
                            onPress={ () =>
                            {
                                window.open( ' https://actavivo.net/how-to/' );
                            } }
                        >
                            <Text
                                marginTop={ '20px' }
                                marginLeft={ '10px' }
                                fontSize={ '16px' }
                                color={ '#00A7BC' }
                                lineHeight={ '10px' }
                            >
                                { Loc.currentLang.menu.help }
                            </Text>
                        </Pressable>
                        <Pressable
                            isDisabled={ currentUser?.status === 'Deleted' }
                            onPress={ () =>
                            {
                                navigate( '/teams/78b5b063-fd3e-4925-92bd-2a1888c6a46a/' + currentUser?.region, {
                                    state: {
                                        team: {
                                            id: '78b5b063-fd3e-4925-92bd-2a1888c6a46a',
                                            pk: 'a46a',
                                            region: currentUser?.region,
                                            type: 'Organization',
                                            name: 'Actavivo',
                                        },
                                        type: 'Feedback'
                                    },
                                } );
                            } }
                        >
                            <Text
                                marginTop={ '20px' }
                                marginLeft={ '10px' }
                                fontSize={ '16px' }
                                color={ '#00A7BC' }
                                lineHeight={ '10px' }
                            >
                                { Loc.currentLang.menu.feedBack }
                            </Text>
                        </Pressable>
                        <Divider marginTop={ '15px' }></Divider>
                        <Pressable
                            isDisabled={ currentUser?.status === 'Deleted' }
                            onPress={ () =>
                            {
                                setshowAddinviteModal( true );
                                setShowProfile( false );
                                setpersonEmail( '' );
                                setpersonname( '' );
                                setpersonPhone( '' );
                                setisLoading( false );
                            } }
                        >
                            <Text
                                marginTop={ '20px' }
                                marginLeft={ '10px' }
                                fontSize={ '16px' }
                                color={ '#00A7BC' }
                                lineHeight={ '10px' }
                            >
                                { Loc.currentLang.menu.inviteFriends }
                            </Text>
                        </Pressable>
                        <Button
                            width={ '100%' }
                            height={ '38px' }
                            alignContent={ 'space-around' }
                            alignSelf={ 'center' }
                            marginTop={ '20px' }
                            borderRadius={ '6px' }
                            background={ '#EEEEEE' }
                            onPress={ () => { handleLogout() } }
                        >
                            <Text fontSize={ 14 } color={ '#00A7BC' }>
                                { Loc.currentLang.menu.logout }
                            </Text>
                        </Button>
                    </VStack>
                    { currentUser?.wards?.length > 0 && <Divider /> }
                    { !ispageLoading && ( <ScrollView >
                        <VStack minHeight={ '0px' } maxHeight={ '140px' } overflowY={ 'scroll' }>
                            <Text
                                marginTop={ '7px' }
                                fontWeight={ 'bold' }
                                marginLeft={ '10px' }
                                fontSize={ '16px' }
                                lineHeight={ '24px' }
                            >
                                { currentUser?.wards?.length > 0 && !loginLink ? Loc.currentLang.menu.myDependants : '' }
                            </Text>
                            { currentUser?.wards?.length > 0 &&
                                userisguardian &&
                                !loginLink?.id &&
                                currentUser?.wards?.map( ( item, index ) => (
                                    <>
                                        <VStack padding={ '2px' }>
                                            <Pressable
                                                onPress={ () =>
                                                {
                                                    handleuserchange( item );
                                                } }
                                            >
                                                <HStack marginBottom={ '5px' }>
                                                    <Box rounded='full' marginLeft={ '18px' }>
                                                        <ProfilePic width={ '35px' } height={ '35px' } link={ item } />
                                                    </Box>
                                                    <VStack marginLeft={ '10px' }>
                                                        <Text
                                                            fontWeight='medium'
                                                            fontSize={ '16px' }
                                                            fontFamily={ 'Roboto' }
                                                            numberOfLines={ 1 }
                                                            width={ '110px' }
                                                        >
                                                            { item?.title }
                                                        </Text>

                                                        <Text
                                                            fontSize={ '12px' }
                                                            fontFamily={ 'Roboto' }
                                                            marginLeft={ '1px' }
                                                            color={ '#00A7BC' }
                                                            lineHeight={ '24px' }
                                                        >
                                                            { Loc.currentLang.menu.viewMyPage }
                                                        </Text>
                                                    </VStack>
                                                </HStack>
                                            </Pressable>
                                        </VStack>
                                    </>
                                ) ) }
                            { ( loginLink?.id && loginLink?.id !== currentUser?.id ) &&
                                ( <VStack padding={ '2px' }>
                                    <Pressable
                                        onPress={ () =>
                                        {
                                            handleuserchange( loginLink );
                                        } }
                                    >
                                        <HStack marginBottom={ '5px' }>
                                            <Box rounded='full' marginLeft={ '18px' }>
                                                <ProfilePic
                                                    width={ '35px' }
                                                    height={ '35px' }
                                                    link={ Person.getLink( loginLink ) }
                                                />
                                            </Box>
                                            <VStack marginLeft={ '10px' }>
                                                <Text
                                                    fontWeight='medium'
                                                    fontSize={ '16px' }
                                                    fontFamily={ 'Roboto' }
                                                    numberOfLines={ 1 }
                                                    width={ '110px' }
                                                >
                                                    { Person.getLink( loginLink )?.title }
                                                </Text>

                                                <Text
                                                    fontSize={ '12px' }
                                                    fontFamily={ 'Roboto' }
                                                    marginLeft={ '1px' }
                                                    color={ '#00A7BC' }
                                                    lineHeight={ '24px' }
                                                >
                                                    { Loc.currentLang.menu.viewMyPage }
                                                </Text>
                                            </VStack>
                                        </HStack>
                                    </Pressable>
                                </VStack>
                                ) }
                        </VStack>
                    </ScrollView> ) }
                    { ispageLoading && <HStack margin={ '20px' } justifyContent={ 'center' }> <div className='loader'></div> </HStack> }
                </Box>
            ) }
        </>
    );
};

export default React.memo( Header );
